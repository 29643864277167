.recall-box {
    background-color: #d9d9d9;
    background-image: url("/images/recall/recall_bg.jpg");
    background-repeat: no-repeat;
    background-position: center bottom;
    color: #232222;
    padding-top: 50px;
    padding-bottom: 50px;

    @include screen-sm {
        //padding-top: 180px;
        padding-bottom: 140px;
    }


    @include screen-lg {
        padding-top: 75px;
    }


    h1,
    h2 {
        text-align: center;
    }

    h1 {
        color: $brand-primary;
        font-size: 25px;
        font-weight: 300;
        margin-bottom: 35px;

        @include screen-sm {
            font-size: 36px;
        }
    }

    h2 {
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 40px;

        @include screen-xs {
            br {
                display: none;
            }
        }


        @include screen-sm {
            font-size: 28px;
            margin-bottom: 25px;
        }
    }

    .recall-form {
        overflow: hidden;

        input {
            background-color: #fff;
            border: 1px solid #5d5d5d;
            color: #8e8e8e;
            text-align: center;
            height: 44px;
            width: 100%;
            margin-bottom: 20px;

            @include screen-sm {
                float: left;
                text-align: left;
                padding: 0 15px 0 40px;
                height: 64px;
                width: 66.66666667%;
                margin-bottom: 0;
            }


            @include screen-lg {
                height: 44px;
            }
        }

        button {
            border: 2px solid $brand-primary;
            color: #232222;
            font-weight: 700;
            text-align: center;
            padding: 10px 20px;
            width: 100%;
            position: relative;
            outline: none;

            @include screen-sm {
                float: left;
                padding: 18px 20px;
                width: 33.33333333%;
            }
            @include screen-md {
                padding: 6px 20px;
            }
            @include screen-lg {
                padding: 8px 20px;
            }

            .result-loader {
                background: $red1f;
                display: block;
                height: inherit;
                height: 100%;
                width: 0%;
                transition: width 2s ease;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                &.loading {
                    width: 100%;
                }
            }

            .result-loader-gif {
                bottom: 20%;
                display: none;
                position: absolute;
                right: 0;
                width: 23px;
                left: 0;
                margin: 0 auto;
                &.loading {
                    display: block;
                }
            }
        }

        input, button {
            &:focus {
                outline: none;
            }
        }
    }

    .recall-where {
        text-align: center;
        padding-top: 35px;
        padding-bottom: 35px;

        a {
            color: #232222;
            font-size: 14px;
            text-decoration: underline;

            @include screen-sm {
                font-size: 14px;
            }
        }
    }

    .recall-inc {
        font-size: 14px;
        text-align: center;
        margin-bottom: 35px;
    }

    .recall-free {
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 25px;
        text-align: center;

        @include screen-xs {
            br {
                display: none;
            }
        }

        @include screen-sm {
            font-size: 28px;
            margin-bottom: 35px;
        }

        .line {
            text-decoration: underline;
            font-weight: 500;
        }
    }

    .recall-ad_inner {
        background-color: #cacaca;
        font-size: 14px;
        text-align: center;
        padding: 30px 0;
        margin-bottom: 60px;

        p {
            color: #232222;
            padding-top: 30px;
            padding-bottom: 15px;
            padding: 30px;
            text-align: justify;

            @include screen-sm {
                padding: 30px 0 15px;
            }
        }

        @include screen-sm {
            padding: 50px;
        }
    }

    .recall-vin {
        position: relative;

        @include screen-sm {
            border: 1px solid $brand-primary;
            padding: 50px;
        }


        >span {
            background-color: #d9d9d9;
            font-size: 22px;
            font-weight: 300;
            display: block;
            text-align: center;
            margin-bottom: 25px;

            @include screen-sm {
                display: inline-block;
                font-size: 28px;
                position: absolute;
                left: 50%;
                top: -25px;
                transform: translateX(-50%);
                padding: 0 15px;
            }
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-image: url("/images/recall/vin-bullet.png");
                margin-bottom: 30px;

                span {
                    font-size: 16px;
                    display: block;
                    margin-bottom: 10px;

                    @include screen-sm {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .recall-vin_icons {
        text-align: center;
        overflow: hidden;

        figure {
            display: inline-block;
            margin: 10px 0;
            width: 45%;
            padding: 0 15px;

            @include screen-sm {
                display: block;
                float: left;
                width: 33.3333333333%;
                padding: 0 30px;
            }
        }
    }

    .recall-vin_auto {
        padding-top: 35px;

        figure {
            margin: 0 auto;
            width: 100%;

            @include screen-sm {
                width: 80%;
            }
        }
    }

    #info-vin {
        height: 50px;
    }

    .results-list {
        .line {
            text-decoration: underline;
            font-weight: 600;
        }
    }
}
