/**
 * Media query for extra small screens only
 */
@mixin screen-xs {

    @media (max-width: 767px) {
        @content;
    }

}

/**
 * Media query for extra small screens only
 */
@mixin screen-iphone-portrait {

    @media (min-width: 374px){
        @content;
    }

}

/**
 * Media query for extra small screens only
 */
@mixin screen-iphone-landscape {

    @media (min-width: 670px) {
        @content;
    }

}

/**
 * Media query for extra small screens only
 */
@mixin screen-almost-xs {

    @media (min-width: 500px) {
        @content;
    }

}

/**
 * Media query for small screens and above
 */
@mixin screen-sm {

    @media (min-width: 768px) {
        @content;
    }

}

/**
 * Media query for ipad Landscape
 */
@mixin screen-iPadLandscape {
    @media (min-width: 1023px) {
        @content;
    }
}

/**
 * Media query for small screens only
 */
@mixin screen-xs-only {

    @media (min-width: 320px) and (max-width: 767px) {
        @content;
    }

}

/**
 * Media query for small screens only
 */
@mixin screen-sm-only {

    @media (min-width: 768px) and (max-width: 991px) {
        @content;
    }

}

/**
 * Media query for medium screens and above
 */
@mixin screen-md {

    @media (min-width: 992px) {
        @content;
    }

}

/**
 * Media query for large screens and above
 */
@mixin screen-ipad-landscape {

    @media (min-width: 1023px) {
        @content;
    }

}

/**
 * Media query for large screens and above
 */
@mixin screen-height-1050 {

    @media (min-height: 1040px) and (min-width: 1679px) and (max-width: 1700px) {
        @content;
    }

}


/**
 * Media query for large screens and above
 */
@mixin screen-lg {

    @media (min-width: 1200px) {
        @content;
    }

}

@mixin screen-lgl {

    @media (min-width: 1340px) {
        @content;
    }

}

@mixin screen-lap-only {

    @media (max-height: 768px) and (max-height: 800px) and (min-width: 1200px) {
        @content;
    }

}

@mixin screen-xl {

    @media (min-width: 1441px) {
        @content;
    }

}

@mixin screen-xxl {

    @media (min-width: 1600px) {
        @content;
    }

}

@mixin screen-for-fix-to-much-items-safety {
    @media (min-width: 1760px) {
        @content;
    }
}
@mixin screen-full {

    @media (min-width: 1890px) {
        @content;
    }

}

@mixin screen-iMac24-Height {

    @media (min-height: 1081px) {
        @content;
    }

}

@mixin screen-low-height {

    @media (max-height: 550px) and (min-width: 1200px) {
        @content;
    }

}

@mixin screen-medium-height {

    @media (max-height: 750px) and (min-width: 1200px) {
        @content;
    }

}

@mixin screen-height-1013 {
    @media (min-height: 1013px) {
        @content;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}