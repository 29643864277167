.AboutTop {
    .ShortHeader {
        @include media-breakpoint-down(md) {
            background: url("/images/about/about-header-xs.jpg") no-repeat center top;
            background-size: cover;
        }


        @include media-breakpoint-up(lg) {
            background: url("/images/about/about-header.jpg") no-repeat center top;
            background-size: cover;
        }

    }
}
.AboutBox{
    padding: 50px 0;
    @include media-breakpoint-up(md){
        padding: 80px 0;
    }
    .About-title{
        color:$brand-primary;
        font-size: em(30px);
        font-weight: 200;
        @include media-breakpoint-up(md){
            font-size: em(35px);
        }

        @media #{$mq-2k} {
            font-size: em(50px);
        }
    }
    .About-text{
        color:$brand-alternative;
        font-size: em(14px);
        padding-top: 50px;
        @include media-breakpoint-up(md){
            padding-top: 0;
        }

        .About-title{
            @include media-breakpoint-down(sm){
                display: none;
            }
        }
    }
    .About-store_b{
        padding-top: 50px;
        padding-bottom: 50px;
        @include media-breakpoint-up(md){
            padding-top: 80px;
            padding-bottom: 80px;
        }
        .About-store{
            padding: 0;
            @include media-breakpoint-up(md){
                padding-right: 0;
            }
        }
    }
    .About-info{
        background: url('/images/about/about-bg-red.jpg') no-repeat center center;
        background-size: cover;
        color:white;
        font-size: em(30px);
        font-weight: 200;
        line-height: 1.2;
        display: flex;
        align-items: center;
        padding: 16.333333333%;
        @include media-breakpoint-up(md){
            font-size: em(20px);
            padding: 0 8.666666666%;
        }
        @include media-breakpoint-up(lg){
            font-size: em(30px);
        }
        @media #{$mq-2k} {
            font-size: em(20px);
        }
    }
    .About-more{
        color:$brand-primary;
        font-size: em(30px);
        font-weight: 200;
        margin-bottom: 30px;
        @include media-breakpoint-up(md){
            font-size: em(25px);
        }
    }
    .About-more_text{
        color:$brand-alternative;
        font-size: em(14px);

        &.second {
            margin-top: 30px;
        }
    }

    p {
        margin-bottom: 15px;
    }
}

.social-responsibility {

    .image-box {
        background-image: url('../images/about/social-responsibility-image-box.jpg');
        background-size: cover;
        background-position: center center;
        width: 475px;
        height: 374px;
        padding: 107px 40px 46px 40px;
        object-fit: contain;

        label {
            color: white;
            text-align: center;
            font-size: 30px;
            font-weight: 200;
            display: block;
            margin: 0 auto;
            width: 295px;
        }
    }

    .text-box {
        background-color: #e5e5e5;

        p {
            color: #848484;
            font-size: 14px;
            font-weight: normal;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 20px;
            padding-top: 20px;

            @include media-breakpoint-up(lg) {
                padding-left: 80px;
                padding-right: 80px;
                padding-bottom: 20px;
                padding-top: 70px;
            }

        }
    }

    .big-box{
        background-image: url('../images/extras/panama/responsabilidad-social/sumarse.png');
        background-size: 95% auto;
        background-position: right 94%;
        background-repeat: no-repeat;
        padding-top: 20px;
        padding-bottom: 200px;
        position: relative;

        @include media-breakpoint-up(md) {
            padding-bottom: 300px;
        }

        @include media-breakpoint-up(lg) {
            background-position: right 118%;
            padding-bottom: 550px;
        }

        p {
            font-size: 14px;
            font-weight: normal;
            color: #848484;
        }

        a {
            color: #0c83ec;
            font-size: 16px;
            font-weight: bold;
            position: absolute;
            display: block;
            text-align: center;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 5%;
        }
    }
}
