.Service-center {
    .ShortHeader {
        @include media-breakpoint-down(md) {
            background: url("/images/services/servicecenter-header-xs.jpg") no-repeat center top;
            background-size: cover;
        }


        @include media-breakpoint-up(lg) {
            background: url("/images/services/servicecenter-header.jpg") no-repeat center top;
            background-size: cover;
        }
    }
}

.Online-appointment {
    .ShortHeader {
        @include media-breakpoint-down(md) {
            background: url("/images/services/onlineappointment-header-xs.jpg") no-repeat center top;
            background-size: cover;
        }

        @include media-breakpoint-up(lg) {
            background: url("/images/services/onlineappointment-header.jpg") no-repeat center top;
            background-size: cover;
        }
    }
}

.know-your-honda {
    .ShortHeader {
        @include media-breakpoint-down(md) {
            background: url("/images/services/know-your-honda-header-xs.jpg") no-repeat center top;
            background-size: cover;
        }

        @include media-breakpoint-up(lg) {
            background: url("/images/services/know-your-honda-header.jpg") no-repeat center top;
            background-size: cover;
        }
    }
}

.parts-request {
    .ShortHeader {
        @include media-breakpoint-down(md) {
            background: url("/images/services/know-your-honda-header-xs.jpg") no-repeat center top;
            background-size: cover;
        }

        @include media-breakpoint-up(lg) {
            background: url("/images/services/know-your-honda-header.jpg") no-repeat center top;
            background-size: cover;
        }
    }
}

.ServiceCenter-top {
    padding: 50px 0;

    p {
        color: $brand-alternative;
        font-size: em(14px);
        margin-bottom: 3px;
    }
}

.ServiceCenter-title {
    color: $brand-primary;
    font-size: em(30px);
    font-weight: 200;
    line-height: 1.2;
    display: block;
    margin-bottom: 10px;
}

.ServiceCenter-img {
    @include media-breakpoint-up(lg) {
        padding: 0;
    }

    p {
        margin-bottom: 3px;
    }
}

.ServiceCenter-middleForm {
    background: $aux-gray1;

    @include media-breakpoint-up(md) {
        background: url('/images/services/service-center-bg.jpg') no-repeat center center;
        background-size: cover;
    }

    padding: 70px 0;

    .ServiceCenter-title {
        margin-bottom: 15px;
    }

    .middleForm-make {
        @include media-breakpoint-down(sm) {
            border-bottom: 1px solid #e1e1e1;
            padding-bottom: 20px;
            margin-bottom: 25px;
        }

    }

    .ServiceShedule {
        .ActionsSet {
            text-align: center;

            @include media-breakpoint-up(xl) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            >span {
                color: $brand-alternative;
                font-size: em(10px);
                display: block;
                text-align: center;
                padding-top: 15px;

                @include media-breakpoint-up(xl) {
                    padding-top: 0;
                    padding-left: 15px;
                    text-align: left;
                }
            }
        }
    }

    p {
        margin-bottom: 15px;

        label {
            font-weight: bold;
            font-size: em(16px);
            margin: 0;
        }
    }
}

.ServiceCenter-last {
    color: $brand-alternative;
    font-size: em(14px);
    padding: 50px 0;

    @include media-breakpoint-up(md) {
        padding: 70px 0;
    }
}

//dealer
.Service-center_stores {
    padding-top: 35px;
    padding-bottom: 50px;

    @include media-breakpoint-up(md) {
        padding-bottom: 150px;
        max-height: 800px;
        overflow-y: scroll;
    }


    >span {
        color: $brand-alternative;
        display: block;
        padding-left: 28px;
        margin-bottom: 30px;
    }
}

.Service-locator {

    border: 1px solid $brand-light;
    cursor: pointer;
    border-radius: 7px;
    color: $brand-alternative;
    font-size: em(14px);
    padding: 20px 28px;
    margin-bottom: 15px;

    p {
        margin: 0;
    }

    p b {
        font-size: 1.2em;
        text-indent: 0.1em;
        display: block;
        margin-top: 1.5em;
        margin-bottom: 0.1em;
        margin-left: -1em;
    }


    .Service-locator_name {

        color: $brand-third;
        padding-bottom: 0.5em;
        font-size: em(18px);
        font-weight: 200;
        display: flex;
        margin-bottom: 10px;

        em {
            background: white;
            border: 1px solid $brand-third;
            border-radius: 20px;
            color: $brand-third;
            font-size: em(14px);
            height: 25px;
            width: 25px;
            margin-right: 10px;
        }
    }

    .BtnStd {
        color: $brand-light;
        font-size: em(12px);
        font-weight: bold;
        width: 120px;
        margin-top: 10px;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    a {
        &.BtnStd {
            margin-top: 20px;
            text-align: center;
            display: block;
        }
    }

    address {
        color: $brand-alternative;
        font-size: em(14px);
        margin-bottom: 0;
    }

    dd {
        margin-bottom: 0;
    }

    @include hover {
        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #e8e8e8;

        >span {
            //color: $brand-primary;

            em {
                background-color: $brand-primary;
                border: 1px solid $brand-primary;
                color: $brand-light;
            }
        }
        .Service-locator_name{
            color:$brand-primary;
        }
    }


    &.active {
        >span {
            em {
                background-color: $brand-primary;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        margin-left: -20%;
    }

    @include media-breakpoint-down(sm) {
        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #e8e8e8;
        width: 70%;
        margin: 0 auto 30px auto;
        padding: 20px 15%;

        em {
            display: none;
        }

        .Service-locator_name {
            text-align: center;
            display: block;
            margin-left: -1em;
            font-size: 1.4em;
        }

        .BtnStd.BtnStd-primary {
           //margin: 0 auto;
            display: block;
            text-align: center;
            position: relative;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 2em;
            margin-bottom: 1.2em;
        }



    }

    @include media-breakpoint-down(xs) {
        width: inherit;
    }
}

.Service-center_map {
    background-color: #eee;

    @include media-breakpoint-down(sm) {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        height: 100vh;
        padding: 0;

        &.showMap {
            display: block;
        }
    }


    @include media-breakpoint-up(md) {
        padding: 0;
    }


    .mapBox {
        height: 100vh;
        width: 100%;
        @include media-breakpoint-up(md){
            height: 100%;
        }
    }

    .closeMap {
        position: absolute;
        top: 8px;
        right: 70px;
        z-index: 2;
        display: block;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .map {
        height: 100%;
    }

    .div-map {
        background-size: cover;
        background-position: center center;
        height: 100%;
        width: 100%;
    }
}

.DealerBox {
    .ShortHeader {
        @include media-breakpoint-down(md) {
            background: url("/images/dealer-header-xs.jpg") no-repeat center top;
            background-size: cover;
        }


        @include media-breakpoint-up(lg) {
            background: url("/images/dealer-header.jpg") no-repeat center top;
            background-size: cover;
        }
    }
}

.PartRequest {

    padding-top: 50px;

    @include media-breakpoint-up(md) {
        padding: 50px 0;
    }

    .ContactBox-dealer {
        padding-bottom: 50px;

        h3 {
            color: $brand-primary;
            font-size: em(30px);
            font-weight: 200;

            @include media-breakpoint-up(md) {
                font-size: em(30px);
            }
        }

        .description-box {
            max-width: 90%;
        }
    }

    .ContactBox-form {
        background-color: #f6f6f6;
        border-top: solid 1px #dfdfdf;
        padding: 25px 35px;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            background-color: #fff;
            border-radius: 7px;
            box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
            border: solid 1px #e8e8e8;
            padding: 30px 60px;
        }

        .Contact-inst {
            display: block;
            margin-bottom: 20px;
        }

        input,
        select {
            color: $brand-third;
            font-size: em(16px);
        }

        select {
            background: url("/images/arrow-down-b.svg") #fbfbfb no-repeat 95% center;
            width: 100%;
            height: 50px;
            border-radius: 4px;
            border: solid 1px #dedede;
            box-shadow: inset 0 1px 7px 0 rgba(0, 0, 0, 0.13);
            color: $brand-primary;
            -moz-appearance: none;
            -ms-appearance: none;
            appearance: none;
            font-size: em(16px);
            padding-left: 20px;
            text-transform: uppercase;

            @media #{$mq-2k} {
                font-size: em(16px);
            }
        }

        select:focus {
            outline: none;
        }

        textarea {
            min-height: 180px;
            max-height: 180px;
            height: 180px;
        }

        label {
            color: $brand-primary;
        }

        .BtnStd {
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

}
