.Recall-top {
    .ShortHeader {
        @include media-breakpoint-down(md) {
            background: url("/images/services/recall/recall-header-xs.jpg") no-repeat center top;
            background-size: cover;
        }


        @include media-breakpoint-up(lg) {
            background: url("/images/services/recall/recall-header.jpg") no-repeat center top;
            background-size: cover;
        }

        .ShortHeader-title {
            @media #{$mq-2k} {
                font-size: em(55px);
            }
        }
    }
}

.Recall-vin {
    @include media-breakpoint-down(sm) {
        background: url("/images/services/recall/safety-bg-xs.jpg") no-repeat center 43%;
        background-size: contain;
    }


    @include media-breakpoint-up(md) {
        background: url("/images/services/recall/safety-bg.jpg") no-repeat center 250px;
        background-size: contain;
    }


    @media (min-width: 1500px) {
        background: url("/images/services/recall/safety-bg-xl.jpg") no-repeat center 250px;
        background-size: 100% auto;
    }

    padding: 50px 0;

    .Recall-title {
        color: $brand-primary;
        font-size: em(30px);
        font-weight: 200;
        text-align: center;
        margin: 0 auto 50px;

        @media #{$mq-2k} {
            font-size: em(25px);
        }

        span {
            display: block;
        }
    }

    .Recall-vin_form {
        background: white;
        border-radius: 7px;
        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #e8e8e8;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;
        max-width: 650px;
        margin: 0 auto;

        @media #{$mq-2k} {
            max-width: 750px;
        }

        label {
            font-size: em(12px);
            display: flex;
            justify-content: space-between;

            @media #{$mq-2k} {
                font-size: em(9px);
            }

            span:last-child {
                color: $brand-alternative;
            }
        }

        button {
            margin-top: 24px;

            @include media-breakpoint-up(md) {
                width: 100%;
            }

            @media #{$mq-2k} {
                font-size: em(9px);
            }
        }

        #error-catch {
            @media #{$mq-2k} {
                //font-size: em(9px);
            }
        }

        .StaticUnderLine {
            &.u-link {
                @media #{$mq-2k} {
                    font-size: em(9px);
                }
            }
        }

        .Recall-vin_formsubmit {
            text-align: center;
        }

        .Recall-vin_find {
            @include media-breakpoint-down(sm) {
                text-align: center;
            }


            a {
                margin-top: 10px;

                @include media-breakpoint-down(sm) {
                    margin-top: 15px;
                }
            }
        }
    }

    .Recall-vin_text {
        font-size: em(14px);
        text-align: center;
        margin: 50px auto;

        @include media-breakpoint-up(md) {
            font-size: em(14px);
        }


        span {
            color: $brand-primary;
            font-size: em(30px);
            font-weight: 200;
            line-height: 1.2;
            display: block;
            padding-top: 40px;

            strong {
                display: block;
            }
        }
    }

    .Recall-vin_safety {
        border-radius: 7px;
        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #e8e8e8;
        overflow: hidden;

        .safety-text {
            background-color: $brand-third;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 35px 15px;

            @include media-breakpoint-up(md) {
                padding: 43px 15px;
            }


            figure {
                text-align: center;
                width: 60px;
                margin-right: 15px;
                margin-bottom: 0;

                @include media-breakpoint-up(md) {
                    width: auto;
                    margin-right: 20px;
                }
            }

            h3 {
                font-size: em(26px);
                font-weight: 200;

                @include media-breakpoint-up(md) {
                    font-size: em(40px);
                }
            }
        }

        .safety-affected {
            @include media-breakpoint-down(sm) {
                background: url("/images/services/recall/safety-xs.jpg") no-repeat center center;
            }


            @include media-breakpoint-up(md) {
                background: url("/images/services/recall/safety.jpg") no-repeat center center;
            }


            color: white;
            font-size: em(18px);
            padding-top: 20px;
            padding-bottom: 20px;

            @include media-breakpoint-up(md) {
                font-size: em(18px);
                padding-top: 0;
                padding-bottom: 0;
            }


            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            span {
                font-size: em(25px);
                font-weight: bold;
                display: block;

                @include media-breakpoint-up(md) {
                    font-size: 25px;
                }
            }
        }

        .safety_info {
            background-color: #fff;
            color: $brand-third;
            font-size: em(14px);
            padding: 30px;

            @include media-breakpoint-up(md) {
                padding: 37px 60px;
            }
        }
    }

    .Recall-vin_info {
        margin-top: 50px;
        margin-bottom: 50px;

        .vin-info_title {
            color: $brand-primary;
            font-size: em(30px);
            font-weight: 200;

            @include media-breakpoint-up(md) {
                font-size: em(30px);
            }
        }

        .vin-info-text {
            color: $brand-alternative;
            font-size: em(14px);

            span {
                color: $brand-third;
                display: block;
                font-weight: bold;
            }
        }
    }

    .Recall-vin_img {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 68%, rgba(242, 242, 242, 1) 87%, rgba(255, 255, 255, 1) 100%);
        display: flex;
        text-align: center;
        width: 100%;

        @include media-breakpoint-up(md) {
            display: block;
            margin: 0 auto;
        }
        @include media-breakpoint-up(md) {

        }

        .Recall-vin_img-row {
            margin: 20px auto;
        }
    }
}

//results
.Recall-results {
    background-color: #f6f6f6;
    padding: 50px 0 150px 0;

    @include media-breakpoint-up(md) {
        padding: 70px 0 200px 0;
    }


    .Recall-results_titles {
        position: relative;

        .closeVin {
            cursor: pointer;
            position: absolute;
            top: -40px;
            right: 20px;

            @include media-breakpoint-up(md) {
                top: -20px;
            }
        }
    }

    .SecTitle {
        display: block;
        text-align: center;
        margin-bottom: 0;
        line-height: 1.1;

        @include media-breakpoint-up(md) {
            line-height: normal;
        }
    }

    .VinCode {
        display: block;
        font-size: em(14px);
        text-align: center;
    }

    .VinCar-meta {
        display: flex;
        justify-content: center;
        margin: 50px 0;

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            margin: 20px 0;
        }


        span {
            border-bottom: 1px solid #b0b0b0;
            color: $brand-alternative;
            font-size: em(12px);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            text-align: center;
            line-height: 1;
            margin: 10px 15px;

            @include media-breakpoint-up(md) {
                margin: 0 30px;
            }


            strong {
                color: $brand-third;
                font-size: em(16px);
                font-weight: normal;
                padding: 5px 0;
                line-height: normal;
            }
        }
    }

    .VinCar-elem {
        padding: 25px;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            padding: 35px;
        }


        .VinResult-titles {
            color: $brand-primary;
            font-size: em(24px);
            font-weight: 200;
            display: block;
            line-height: 1.21;
            margin-bottom: 10px;

            @include media-breakpoint-up(md) {
                font-size: em(30px);
                line-height: normal;
            }
        }
    }

    .VinCar-safety_list {
        color: $brand-alternative;
        font-size: em(14px);

        .VinCar-safety_in {
            color: $brand-third;
        }

        .VinCar-safety_warning {
            color: $brand-primary;
        }

        >span {
            display: block;
        }
    }
}

.toCenterBox {
    margin: 0 auto;
}

.Vin-affected {
    background: url("/images/services/recall/recall-results-bg.jpg") no-repeat center center;
    background-size: cover;
    border-radius: 10px;
    color: white;
    font-size: em(16px);
    text-align: center;
    padding: 25px 20px;
    margin-top: -130px;

    @include media-breakpoint-up(md) {
        font-size: em(18px);
        padding: 50px 35px;
        margin-top: -100px;
    }
}

.Recall-Vin_steps {
    padding: 50px 0;

    .steps-whats-next {
        color: $brand-primary;
        font-size: em(30px);
        font-weight: 200;
        text-align: center;
        display: block;
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
            font-size: em(30px);
        }
    }

    p {
        color: $brand-third;
        font-size: em(14px);
        text-align: center;

        @include media-breakpoint-up(md) {
            padding: 0 50px;
        }
    }

    .Vin-affected_form {
        label {
            color: $brand-primary;
            font-size: em(12px);
            margin-bottom: 5px;
        }

        input {
            color: $brand-third;
            font-size: em(16px);
            margin-bottom: 10px;

            &.error {
                border: 1px solid $brand-primary;
            }
        }

        textarea {
            height: 100px;
            min-height: 100px;
            max-height: 100px;
            overflow: auto;

            &.error {
                border: 1px solid $brand-primary;
            }
        }

        .form-submit {
            text-align: center;
            padding: 20px 0;
        }
    }
}

.Recall-ctc {
    background-color: #f6f6f6;
    padding: 50px 0;

    .Recall-ctc_title {
        color: $brand-primary;
        font-size: em(30px);
        font-weight: 200;

        @include media-breakpoint-up(md) {
            font-size: em(30px);
        }
    }

    .Recall-ctc_data {
        color: $brand-alternative;
        font-size: em(14px);

        .data-line {
            padding: 5px 0 5px 35px;

            &.h-phone {
                background: url("/images/icon-phone.svg") no-repeat left center;
                background-size: 18px;
            }

            &.h-location {
                background: url("/images/icon-location.svg") no-repeat 5px center;
                background-size: 10px;
            }

            &.h-mail {
                background: url("/images/icon-mail.svg") no-repeat 5px center;
                background-size: 15px;
            }

            &.h-date {
                background: url("/images/icon-date-red.svg") no-repeat 5px center;
                background-size: 15px;
            }

            &.h-whatsapp {
                background: url("/images/icon-whatsapp.png") no-repeat 5px center;
                background-size: 15px;
            }
        }

        span {
            color: $brand-third;
        }
    }
}
.wrap-result {
    background-color: white;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 15;

    .Recall-results_affected {
        background-color: white;
    }

    .form-message {
        &.ok {
            color: $success !important;
        }
    }
}
