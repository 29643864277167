.Express{
    .ShortHeader{
        @include media-breakpoint-down(md) {
            background: url("/images/services/service-xs.jpg") no-repeat center top;
            background-size: cover;
        }


        @include media-breakpoint-up(lg) {
            background: url("/images/services/service-dsk.jpg") no-repeat center top;
            background-size: cover;
        }
    }
    &-b1{
        padding-top: 50px;
        @include media-breakpoint-up(md){
            padding-top: 80px;
        }
        &_desc{

        }
        &_red{
            background: url("/images/services/express-red.jpg") no-repeat center top;
            background-size: cover;
            color:white;
            font-size: 18px;
            font-weight: 300;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 50px;
            @include media-breakpoint-up(md){
                font-size: 20px;
                align-items: flex-start;
            }
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                display: block;
            }
            figure{
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 0;
                width: 75%;
            }
            p{
                text-align: center;
                padding: 0 5%;
                @include media-breakpoint-up(md){
                    text-align: left;
                    padding: 0;
                }
            }
        }
        &_img{
            padding: 0;
            img{
                @include media-breakpoint-between(md,lg){
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    &-items{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    &-icon{
        margin-bottom: 50px;

        @include media-breakpoint-up(md){
            margin-bottom: 0;
            text-align: center;
        }
        &_img{
            @include media-breakpoint-up(md){
                height: 70px;
                margin-bottom: 15px;
            }
        }
        &_title{
            color:$brand-primary;
            font-size: 20px;
            font-weight: 200;
            @include media-breakpoint-up(md){
                font-size: 22px;
            }
        }
    }

    &-b2{
        background: #f1f1f1;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    &-inc_img{
        margin-bottom: 40px;
        @include media-breakpoint-up(md){
            margin-bottom: 0;
        }
    }
    &-inc_txt{
        font-size: 14px;
        ul{
            li{
                list-style-image: url('/images/recall/vin-bullet.png');
                padding-right: 15px;
            }
        }
    }
    &-b3{
        padding-top: 50px;
        padding-bottom: 50px;
        @include media-breakpoint-up(md){
            padding-top: 65px;
            padding-bottom: 65px;
        }
        .BuildElem .BuildElem-inner {
            figure img{
                height: 45px;
            }
            h3 {
                font-size: 20px;
                + span{
                    display: inline-block;
                    text-transform: uppercase;
                    min-height: 0;
                }
            }
        }
    }
}
