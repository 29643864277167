.ui-Modal {
    background-color: #cacaca;
    border-bottom: 4px solid $brand-primary;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 83.33333333%;
    padding-top: 25px;
    z-index: 12;
    opacity: 0;
    visibility: hidden;

    //transition: all 500ms ease;
    @include screen-sm {
        padding-top: 50px;
    }

    @include screen-md {
        top: 130px;
        width: 100%;
        max-width: 66.66666667%;
    }

    @include screen-xl {
        width: 100%;
        max-width: 50%;
    }

    .ModalTitle {
        color: #18434f;
        font-size: 24px;
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 50px;

        @include screen-sm {
            font-size: 30px;
        }
    }

    .ModalSpacer {
        text-align: right;
        padding: 0 15px;

        @include screen-sm {
            padding: 0 50px;
        }
    }

    .ModalClose {
        cursor: pointer;
        display: inline-block;
        height: 50px;
        width: 50px;
        text-align: center;
        position: relative;

        @include screen-sm {
            top: -25px;
        }
    }

    .ModalContent {
        padding: 0 8.33333333%;
    }
}
.ui-overlay{
    background-color: rgba(0, 0, 0, .8);
    height: auto;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 11;
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease;

    &.visible {
        opacity: 1;
        visibility: visible;
    }
}

.RecallModal{
    &.ShowModal{
        opacity: 1;
        visibility: visible;
    }
    .ModalPad{
        padding: 0 8.33333333%;
    }

    .ModalVin{
        background-color: #c2c0c0;
        color:#2e2e2e;
        padding: 30px;
        margin-bottom: 30px;
        @include screen-sm{
            padding: 50px;
        }
        .ModalVin-id{
            text-align: center;
            margin-top: -67px;
            span{
                background-color: #c2c0c0;
                display: inline-block;
                font-weight: 700;
                padding: 0 15px;
                margin-bottom: 30px;
                @include screen-sm{
                    font-size: 20px;
                }
            }
        }
        strong{
            //color:yellow;
        }
        .ModalVin-spec{
            @include screen-xs{
                font-size: 14px;
            }
        }
    }
    .ModalVin-list{
        color:$brand-primary;
        padding: 35px 25px;
        ul{
            margin: 0;
            padding: 0;
            li{
                background: white;
                color: #333;
                display: block;
                padding: 25px 0;
                margin-bottom: 10px;
                @include screen-sm {
                    padding-left: 50px;
                }
                span {
                    display: block;

                    &.results-title {
                        color: $brand-primary;
                        font-size: 15px;
                        text-transform: uppercase;
                    }

                    em {
                        font-weight: bold;
                        font-style: normal;
                        &.red {
                            color: #e6001a;
                        }
                    }
                }
                >div{
                    padding: 0;
                }
            }
        }
        &.empty{
            color: $brand-primary;
            font-weight: 700;
            text-align: center;
            padding: 50px 25px;
        }
    }
    .RecallModal-b2{
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
        >span{
            display: block;
            font-size: 30px;
            font-weight: 300;
            margin-bottom: 35px;
            @include screen-sm{
                font-size: 30px;
            }
        }
        >p{
            color: #2e2e2e;
            @include screen-sm{
                padding: 0 50px;
            }
        }
        >h3{
            font-size: 20px;
            font-weight: 300;
            margin: 30px 0 25px 0;
            @include screen-sm{
                font-size: 20px;
            }
        }
    }
    .recall-line {
        background-color: $brand-primary;
        height: 4px;
    }
    .RecallModal-form{
        input, textarea{
            background-color: #fff;
            border: 1px solid #5d5d5d;
            color:#8e8e8e;
            height: 50px;
            width: 100%;
            padding: 0 10px;
            margin-bottom: 15px;
            transition: border 500ms ease;
            &:focus{
                border:1px solid $brand-primary;
                outline: none;
            }

            &.error {
                border:1px solid $brand-primary;
            }
        }
        textarea{
            height: 180px;
            max-height: 180px;
            min-height: 180px;
            overflow: auto;
        }
        button{
            background-color:$brand-primary;
            color:white;
            margin-top: 26px;
            text-align: center;
            padding: 10px 0;
            height: 50px;
            width: 250px;

            &:disabled {
                background-color: #636363;
                border: 1px solid #969696;
                color: white;
            }
        }
        .form-message {
            display: block;
            font-size: 14px;
            text-align: center;

            &.error {
                color: #e82c2a;
            }
            &.ok {
                color: green;
            }
        }
    }
    .RecallModal-footer{
        background-color: #bdbbbb;
        color:#2e2e2e;
        font-size: 14px;
        padding-top: 50px;
        padding-bottom: 50px;;
        strong{
            color:#2e2e2e;
        }
    }

}
