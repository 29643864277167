.centerTaller-titles{
    h2{
        color:$brand-primary;
        margin-bottom: 30px;
    }
}
.centerTaller_img{
    margin-bottom: 40px;
    @include media-breakpoint-up(lg){
        margin-bottom: 0;
    }
}
.centerDepto{
    padding-top: 50px;
    padding-bottom: 50px;
    .Center-block-1{
        h2{
            color:$brand-primary;
            font-size: 30px;
            font-weight: 200;
            line-height: 1.2;
            margin-bottom: 20px;
            @include media-breakpoint-up(md){
                font-size: 30px;
            }
        }
        >p{
            color: $brand-alternative;
            font-size: 14px;
            font-weight: 200;
            margin-bottom: 20px;
        }
        .slider-controls{
            @include media-breakpoint-down(md){
                justify-content: flex-start;
                padding-top: 0;
            }
            @include media-breakpoint-up(md){
                position: initial;
            }

            .slick-arrow {
                position: absolute;
                left: 0;
                z-index: 10;

                &.slide-m-next {
                    left: auto;
                    right: 0;
                }
            }

            .slick-dots {
                display: flex;
                align-items: center;
                justify-content: center;
                bottom: -25px;

                .slick-active {
                    button {
                        &:before {
                            color: $brand-primary;
                        }
                    }
                }
            }
        }
    }
    .centerDptoSldr{
        margin-bottom: 0;
    }
    .centerDptoSldr-item{
        display: inline-flex !important;
        flex-wrap: wrap;
        figure{
            @include media-breakpoint-up(md){
                padding-right: 20px;
                width: 45%;
            }
        }
        p{
            font-size: 14px;
            line-height: normal;
            @include media-breakpoint-up(md){
                border-left:1px solid $brand-primary;
                padding-left: 20px;
                width: 55%;
            }
        }
    }
}

.centerTaller-data{
    h2{
        color:$brand-primary;
        text-align: left;
    }
    span{
        color: #191919;
        font-size: 26px;
        font-weight: 200;
        display: block;
        margin-bottom: 20px;
    }
    p{
        color:$brand-alternative;
        font-size: 14px;
    }
}

.centerTitles{
    color:$brand-primary;
    font-size: 20px;
    font-weight: 200;
    display: block;
    @include media-breakpoint-up(md){
        font-size: 30px;
    }
}

.centerTallerMvl{
    background: #f6f6f6;
    padding-top: 50px;
    padding-bottom: 50px;
    @include media-breakpoint-up(md){
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .centerTallerDrop{
        padding-top: 50px;
        .centerTallerCollapse{
            margin-bottom: 40px;
        }
        .collapseHandler{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            span{
                color:$brand-primary;
                font-size: 18px;
                font-weight: 200;
                margin: 0 15px;
            }
        }
        .collapseData{
            font-size: 14px;
            margin-left: 45px;
            .cBullet,li{
                background: url('/images/recall/vin-bullet.png') no-repeat left 8px;
                display: block;
                padding-left: 10px;
            }
        }
    }
}

.tallerPintura{
    padding-top: 50px;
    padding-bottom: 50px;
    @include media-breakpoint-up(md){
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .tallerPintura-services{
        span{
            margin-bottom: 20px;
        }
        ul{
            color: #343434;
            list-style-image: url('/images/recall/vin-bullet.png');
            font-size: 18px;
            font-weight: 200;
        }
    }
}

.tallerEndPintura{
    padding-top: 50px;
    @include media-breakpoint-up(md){
        padding-top: 70px;
    }
    .tallerEndPintura-block{
        margin-bottom: 50px;
    }
    .centerTxt{
        text-align: center;
    }
    .tallerEndPintura-col{
        font-size: 14px;
        margin-bottom: 30px;
        p{
            margin-bottom: 0;
        }
        &:nth-child(2){
            position: relative;
            &::after,&::before{
                background: $brand-primary;
                content: "";
                height: 80%;
                width: 1px;
                position: absolute;
                bottom: 0;
            }
            &::before{
                left: 0;
            }
            &::after{
                right: 0;
            }
            p{
                span{
                    display: block;
                }
            }
        }
        &:nth-child(3){
            //font-size: 20px;
            //font-weight: 200;

            span{
                display: block;
            }
        }
    }
}
.tallerEndPintura_acc{
    strong{
        display: block;
    }
}

#serviceCenterContactForm {
    .row {
        div {
            margin-bottom: 20px;

            label {
                color: $brand-primary;
            }

            textarea {
                height: 100px;
            }
        }
    }
}
