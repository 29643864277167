.online-appointment {
    background-color: #f6f6f6;

    .check-field {
        position: relative;
        margin-top: 10px;
        width: 100%;

        @include media-breakpoint-up(lg) {
            min-height: 55px;
        }
        @include media-breakpoint-up(xl) {
            float: left;
            width: 50%;
            font-size: em(13px);
            margin-top: 0;
            margin-bottom: 21px;
        }

        &.no-effects-xl {
            @include media-breakpoint-up(xl) {
                float: none;
                font-size: em(16px);
                margin: 0;
                width: 100%;
            }
        }

        &.disabled {
            label {
                color: lightgray !important;
                opacity: 0.8;

                &.mark {
                    &:after {
                        border-color: lightgray;
                    }
                }
            }
        }

        label {
            color: $brand-third;

            &.mark {
                width: 20px;
                height: 20px;
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                background: -webkit-gradient(linear, left top, left bottom, from(white), to(white));
                background: linear-gradient(to bottom, white 0%, white 100%);
                border-radius: 4px;
                -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.4);
                box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.4);

                &:after {
                    content: "";
                    width: 12px;
                    height: 7px;
                    position: absolute;
                    top: 6px;
                    left: 4px;
                    border: 3px solid $brand-primary;
                    border-top: none;
                    border-right: none;
                    background: transparent;
                    opacity: 0;
                    transform: rotate(-45deg);
                }
                &:hover::after {
                    opacity: 0.3;
                }

            }

            &.color-primary {
                color: $brand-primary;
                display: inline-block;
                width: 85%;
            }
            &.text {
                color: $brand-third;
                position: relative;
                top: -21px;
                word-break: break-word;
                width: 95%;
                padding-left: 25px;
            }

        }

        input[type=checkbox] {
            visibility: hidden;
            height: auto;
            margin-right: 10px;
            width: 20px;

            &:checked + label:after {
                opacity: 1;
            }
        }
    }
    //.check-field

    .field-text {
        color: $brand-primary;
        font-size: em(16px);
    }
    .wrap-field {
        margin-top: 15px;
    }
    select {
        background: url(/images/arrow-down-b.svg) #fbfbfb no-repeat 95% center;
        width: 100%;
        height: 50px;
        border-radius: 4px;
        border: solid 1px #dedede;
        box-shadow: inset 0 1px 7px 0 rgba(0, 0, 0, 0.13);
        color: $brand-third;
        -moz-appearance: none;
        -ms-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        font-size: em(14px);
        padding-left: 20px;
        text-transform: uppercase;

        &.error {
            border: 2px solid $brand-primary;
        }
    }

    input {
        color: $brand-third;
        background-color: #fbfbfb;
        border-radius: 4px;
        box-shadow: inset 0 1px 7px 0 rgba(0, 0, 0, 0.13);
        border: solid 1px #dedede;
        font-size: 1.375em;
        height: 45px;
        line-height: 45px;
        width: 100%;

        &.error {
            border: 2px solid $brand-primary;
        }
    }

    .form-promos-wrap {
        padding-bottom: 40px;
        padding-top: 40px;

        @include media-breakpoint-up(xl){
            margin: 0 auto;
            width: 1200px;
        }

        .main-wrap {
            border-radius: 7px;
            box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
            border: solid 1px #e8e8e8;
            background-color: #ffffff;
            padding: 25px 15px 25px 15px;

            @include media-breakpoint-up(lg) {
                padding-bottom: 35px;
                padding-left: 50px;
                padding-right: 45px;
            }
        }
        //.main-wrap

        .form-position-title {
            display: block;

            .number {
                background-color: $brand-primary;
                border-radius: 50%;
                color: white;
                float: left;
                display: inline-block;
                font-size: em(16px);
                line-height: 34px;
                height: 32px;
                text-align: center;
                width: 32px;

                @include media-breakpoint-up(lg) {
                    margin-top: 8px;
                }
            }
            //.number

            .title {
                color: $brand-primary;
                float: left;
                padding-left: 16px;
                margin-top: 5px;
                margin-bottom: 20px;
                text-align: left;
                font-size: em(19px);
                width: 85%;

                @include media-breakpoint-up(lg) {
                    font-size: em(23px);
                }
            }
            //.title

        }
        //.form-position-title

        .form-position-subtitle {
            color: $brand-third;
            display: block;
            font-size: em(16px);
        }
        //.form-position-subtitle

        .wrap-services {
            border-radius: 7px;
            box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
            border: solid 1px #e8e8e8;
            background-color: #ffffff;
            padding: 25px 15px 25px 15px;
            margin-top: 10px;

            @include media-breakpoint-up(xl) {
                padding-left: 25px;
                padding-right: 25px;
                //padding-left: 55px;
                //padding-right: 55px;
            }

            .services-title {
                color: $brand-primary;
                font-size: em(20px);
                margin-bottom: 20px;
                margin-top: 20px;

                &.text {
                    color: $brand-third;
                }
            }

            .wrap-fields {
                position: relative;

            }

            .textarea-wrap {
                padding-top: 25px;
                label {
                    color: $brand-primary;
                    font-size: em(16px);
                }
                textarea {
                    background-color: #fbfbfb;
                    box-shadow: inset 0 1px 7px 0 rgba(0, 0, 0, 0.13);
                    border: solid 1px #dedede;
                    height: 100px;
                    border-radius: 4px;
                    padding: 15px;
                    width: 100%;
                }
            }

        }
        //.wrap-services

        .block-form {

            .wrap-steps {
                position: relative;
                overflow: hidden;
                min-height: 750px;
                transition: all 500ms ease;

                @include media-breakpoint-up(md) {
                    margin: 0;
                }
                @include media-breakpoint-up(xl) {
                    min-height: 565px;
                }

                .steps {
                    padding-bottom: 15px;
                    padding-top: 15px;
                    display: none;
                    position: relative;
                    transition: all 500ms ease;

                    &.absolute {
                        position: absolute;
                    }

                    @include media-breakpoint-up(md) {
                        //position: relative;
                    }

                    &.active {
                        display: block;
                    }

                    label {
                        height: 25px;
                    }
                }

            }


            .wrap-buttons {
                margin-top: 25px;
                transition: transform 500ms ease;

                button {
                    display: none;
                    border-radius: 22px;
                    background-color: $brand-primary;
                    height: 46px;
                    font-size: em(12px);
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.08;
                    letter-spacing: normal;
                    text-align: center;
                    color: #ffffff;
                    width: 48%;
                    float: left;
                    text-transform: uppercase;

                    &.active {
                        display: block;
                    }

                    &.next {
                        float: right;
                    }
                }
            }
            //.wrap-buttons
        }
        //.block-form

        .block-offers {
            margin-top: 80px;

            @include media-breakpoint-up(md) {
                margin-top: -72px;
            }
            @include media-breakpoint-up(xl) {
                margin: 0 auto;
                margin-top: -67px;
                max-width: 420px;
                width: 420px;
            }

            .title {
                color: $brand-primary;
                font-size: em(19px);
                margin-bottom: 15px;

                @include media-breakpoint-up(lg) {
                    font-size: em(23px);
                    margin-top: 18px;
                }
            }

            .item {
                position: relative;

                text-align: center;

                .ShadowBox {
                    height: 100%;
                    border: 1px solid;
                    border-color: rgba(0, 0, 0, 0.07);
                }

                .Offers-inner {
                    padding: 30px;
                    height: calc(100% - 98px);
                    display: flex;
                    flex-direction: column;
                    @include media-breakpoint-up(md) {
                        padding-bottom: 0;
                    }
                }

                .Offer-title {
                    font-size: em(20px);
                    font-weight: 200;
                    margin-bottom: 20px;

                    strong {
                        font-weight: bold;
                    }
                }

                .Offer-discount {
                    color: $brand-primary;
                    font-size: em(24px);
                    font-weight: 200;

                    @include media-breakpoint-up(lg) {
                        font-size: em(30px);
                        line-height: 28px;
                        min-height: 50px;
                        margin-bottom: 20px;
                    }
                }

                .Offer-specs {
                    color: $brand-third;
                    border-bottom: 1px solid #eeeeee;
                    font-size: em(12px);
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    flex: auto;
                    //display: flex;
                    align-items: center;
                    line-height: initial;
                    @include media-breakpoint-up(md) {
                        //min-height: 83px;
                    }

                    p {
                        font-size: em(12px);
                    }
                }

                small {
                    display: block;
                }

                .Offer-disc {
                    color: $brand-alternative;
                    font-size: em(9px);
                    padding-bottom: 10px;
                    line-height: normal;
                }

                .Offer-expire {
                    color: $brand-primary;
                    font-size: em(9px);
                    margin-bottom: 20px;
                }

                .Offer-action {
                    background-color: #f8f8f8;
                    border-radius: 7px;
                    padding: 25px;
                    text-align: center;

                    a {
                        line-height: initial;
                    }
                }
            }

            .btn {
                bottom: 25px;
                left: 0;
                right: 0;
                margin: 0 auto;
                position: absolute;
                width: 230px;
            }

            .slick-navDots {
                margin-top: 60px;

                .slick-dots {

                    @include media-breakpoint-up(md) {
                        bottom: 30px;
                        text-align: center;
                    }

                    li {
                        &.slick-active {
                            button {
                                &:before {
                                    color: $brand-primary;
                                }
                            }
                        }

                        button {
                            &:before {
                                color: #999696;
                            }
                        }
                    }
                }


            }
        }

    }
    //.form-promos-wrap

    .common-services-wrap {
        background-color: white;
        padding-bottom: 35px;
        padding-top: 35px;
        transition: all 500ms ease;

        .title {
            color: $brand-primary;
            font-size: em(23px);
            margin-bottom: 15px;

            @include media-breakpoint-up(md) {
                font-size: em(48px);
            }
        }

        ul {
            position: relative;

            @include media-breakpoint-up(lg) {
                margin: 0 auto;
                width: 1046px;
            }

            li {
                height: 60px;
                overflow: hidden;
                width: 100%;
                transition: all 500ms ease;

                &.active {
                    height: auto;

                    em {
                        &.plus {
                            transform: rotate(90deg);

                            &::before {
                                opacity: 0;
                            }
                        }
                    }
                }

                .line {
                    background-color: #d8d8d8;
                    display: block;
                    height: 1px;
                    width: 100%;
                }

                i {
                    display: inline-block;
                    height: 30px;
                    width: 30px;
                    margin-left: 10px;
                    margin-right: 10px;

                    @include media-breakpoint-up(md) {
                        height: 33px;
                        width: 33px;
                    }

                    &.small {
                        margin-left: 0;
                        height: 20px;
                        width: 20px;

                        @include media-breakpoint-up(md) {
                            height: 23px;
                            width: 23px;
                        }
                    }

                    &.icon {
                        background-size: 100% auto;
                        background-repeat: no-repeat;
                        background-position: 0 0;

                        &.filter {
                            background-image: url("/images/know-your-honda/ico-filter.svg");
                        }
                        &.oil {
                            background-image: url("/images/know-your-honda/ico-oil.svg");
                        }
                        &.tires {
                            background-image: url("/images/know-your-honda/ico-tires.svg");
                        }
                        &.engine-filter {
                            background-image: url("/images/know-your-honda/ico-engine-filter.svg");
                        }
                        &.cabin-filter {
                            background-image: url("/images/know-your-honda/ico-cabin-filter.svg");
                        }
                        &.battery-change {
                            background-image: url("/images/know-your-honda/ico-battery-change.svg");
                        }
                        &.maintenance {
                            background-image: url("/images/know-your-honda/ico-maintenance.svg");
                        }
                        &.windshield-wiper {
                            background-image: url("/images/know-your-honda/ico-windshield-wiper.svg");
                            background-position: 0px 10px;
                        }

                    }
                }
                label {
                    &.title {
                        font-size: em(16px);
                        font-weight: 200;
                        margin-bottom: 7px;
                        margin-top: 15px;
                        color: #191919;
                        display: inline-block;
                        text-align: left;
                        width: 80%;

                        @include media-breakpoint-up(md) {
                            font-size: em(20px);
                        }
                    }

                    &.description {
                        margin-top: 10px;
                        margin-bottom: 15px;
                        padding: 15px;

                        @include media-breakpoint-up(md) {
                            font-size: em(16px);
                        }
                    }
                }

                a {
                    margin-bottom: 30px;
                    margin-left: 15px;
                    color: white;
                }

                em{
                    position: absolute;
                    cursor: pointer;
                    height: 15px;
                    width: 15px;
                    transition: all 0.5s ease;
                    float: right;
                    margin-right: 15px;
                    margin-top: 18px;
                    right: 6px;

                    &.plus {

                        &::before,
                        &::after {
                            background-color: $brand-third;
                            content: "";
                            display: block;
                            position: absolute;
                            @include centrar;
                        }

                        &:before {
                            height: 2px;
                            width: 100%;
                            opacity: 1;
                            transition: opacity 500ms ease;
                        }

                        &::after {
                            height: 100%;
                            width: 2px;
                        }
                    }
                }

                .wrap-description {

                }
            }
        }

        .more-services {
            margin-top: 30px;
        }
    }
    //.common-services-wrap

    .locations-wrap {
        background-color: white;

        .div-map {
            background-size: cover;
            background-position: center center;
            height: 100%;
            width: 100%;
        }
    }

    .BuilElem-row {
        .BuildElem-inner {
            @include media-breakpoint-up(md) {
                padding: 40px 10px;
            }

            h3 {
                @include media-breakpoint-up(md) {
                    font-size: em(22px);
                }

                & + span {
                    margin: 0;
                    min-height: 10px;
                }
            }

            .StaticUnderLine {
                font-size: em(15px);
            }

            figure {
                &.express-service {
                    margin: 0 auto 1rem;
                    width: 69%;
                }
               @include media-breakpoint-up(md) {
                   height: 52px;
               }
            }
        }
    }

}

