%bv_hidden {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
%filled_obj {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.KnowModal-sldr {
    position: relative;
    width: 100%;
    /*height: 500px;
    min-height: 500px;*/
    opacity: 0;
    visibility: hidden;
    transition:all 1.2s ease;
    &.slick-initialized {
      opacity: 1;
      visibility: visible;
    }
}

.KnowModal{
    max-width: 730px;
    min-width: 320px;
    width: 100%;
    height: auto;
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 200;
    display: none;

    &.isVideo{
      width: 730px;
      height: 400px;
    }

    &-close{
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 2;
    }
    .slick-slide {
        position: relative;
        //height: 38vw;
        @extend %bv_hidden;
        video {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          min-width: 100%;
          min-height: 100%;
          width: auto;
          height: auto;
          transform:translate(-50%, -50%);
        }
        iframe {
          min-height: 411px;
          position: relative;
          //pointer-events: none;
        }
        figure {
          position: relative;
          height: 100%;
          margin-bottom: 0;
        }
        .image-description, .video-description {
            color: $brand-primary;
            font-size: em(18px);
            font-weight: 400;
            text-align: center;
            padding: 30px;
            background-color: white;
        }
        .slide-image {
          //opacity: 0;
          height: 100%;
          background-size: cover;
          background-position: center;
          // background-color:rgba(#c46897,.38);
          // background-blend-mode:overlay;
          transition:all .8s ease;
          &.show {
            opacity: 1;
          }
        }
        .image-entity {
          width: 100%;
        }
        .loading {
          position: absolute;
          top: 44%;
          left: 0;
          width: 100%;
        }
        .slide-media {
          animation:slideOut .4s cubic-bezier(0.4, 0.29, 0.01, 1);
        }
        &.slick-active {
          z-index: 1;
          .slide-media {
            animation:slideIn 2.4s cubic-bezier(0.4, 0.29, 0.01, 1);
          }
          .caption {
            opacity: 1;
            transform:translateY(0);
            transition:all .7s cubic-bezier(0.32, 0.34, 0, 1.62) .6s;
          }
        }
    }
    .slick-prev, .slick-next{
        background: $brand-primary;
        height: 60px;
        width: 28px;
        z-index: 5;
        &::before{
            content: initial;
        }

        &.slick-disabled {
            background-color: lightgray;
        }
    }
    .slick-prev{
        left: 0;
    }
    .slick-next{
        right: 0;
    }
}

.know-your-honda{
  &.openMdlGal{
    .photos{
      display: block;
    }
  }
  &.openMdlVideo{
    .isVideo{
      display: block;
    }
  }
  &.openMdlGal,&.openMdlVideo{
    .OverlayMenu{
      opacity: 1;
      visibility: visible;
      z-index: 10;
    }
  }
}


@keyframes slideIn {
    from {
      filter:blur(8px);
    }
    to {
      filter:blur(0);
    }
}
@keyframes slideOut {
    from {
      filter:blur(0);
    }
    to {
      filter:blur(8px);
    }
}
