.TestDriveBox {
    .TestDrive-header {
        @include media-breakpoint-down(md) {
            background: url("/images/shopping/test-drive-xs.jpg") no-repeat center top;
            background-size: cover;
            height: 200px;
        }


        @include media-breakpoint-up(lg) {
            background: url("/images/shopping/test-drive.jpg") no-repeat center top;
            background-size: cover;
            height: 225px;
        }


        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
            color: white;
            font-size: em(40px);
            font-weight: 200;

            @include media-breakpoint-up(lg) {
                font-size: em(60px);
            }
        }
    }

    .TestDrive-cont {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .TestDrive-data {
        >span {
            display: block;
            text-align: center;
            margin-bottom: 30px;
        }

        figure {
            img {
                @media #{$mq-2k} {
                    display: block;
                    margin: 0 auto;
                    width: 90%;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        .TestDrive-choice {
            margin-bottom: 50px;

            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: center;
                margin-bottom: 0;
            }
        }

        .TestDrive-select {
            @include media-breakpoint-down(md) {
                text-align: center;
                margin-bottom: 10px;
            }

            span {
                display: block;
                text-align: center;
            }

            @media #{$mq-2k} {
                font-size: em(12px);
            }

            @include media-breakpoint-up(md) {
                margin: 0 30px;
            }

            .roundSelect {
                text-transform: uppercase;
                @include media-breakpoint-down(sm) {
                    min-width: 150px;
                }
            }

            #select-model {
                width: 215px;
            }
        }
    }
}

.TestDrive-form {

    .EstimateForm {
        label {
            color: $brand-primary;
        }

        input,
        select {
            color: $brand-third;
            font-size: em(14px);

            @include media-breakpoint-up(xl) {
                font-size: em(16px);
            }
        }

        @include media-breakpoint-up(md) {
            background-color: #fff;
            border-radius: 7px;
            border: solid 1px #e8e8e8;
            box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
            padding: 50px;
        }

        [type="date"] {
            background: url(/images/icon-date.svg) #fbfbfb 97% 50% no-repeat;
        }

        [type="date"]::-webkit-inner-spin-button {
            display: none;
        }

        [type="date"]::-webkit-calendar-picker-indicator {
            opacity: 0;
        }

        .multiple {
            display: flex;
            justify-content: space-between;
        }

        .EstimateForm-col {
            margin-bottom: 10px;
            width: 30%;

            &:nth-child(1) {
                width: 60%;
            }
        }

        .ActionsSet {
            padding-top: 30px;

            @include media-breakpoint-up(xl) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            >span {
                color: $brand-alternative;
                font-size: em(10px);
                display: block;
                text-align: center;
                padding-top: 15px;

                @include media-breakpoint-up(xl) {
                    padding-top: 0;
                    padding-left: 15px;
                    text-align: left;
                }
            }
        }
    }
}
