.FinancingBox {
    .Financing-header {
        @include media-breakpoint-down(md) {
            background: url("/images/shopping/financing-header-mobile.jpg") no-repeat center top;
            background-size: cover;
            height: 200px;
        }


        @include media-breakpoint-up(lg) {
            background: url("/images/shopping/financing-header.jpg") no-repeat center top;
            background-size: cover;
            height: 225px;
        }


        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
            color: white;
            font-size: em(40px);
            font-weight: 200;

            @include media-breakpoint-up(lg) {
                font-size: em(60px);
            }
        }
    }
}

.FinancingOptions {
    padding: 50px 0;

    @include media-breakpoint-up(lg) {
        padding: 80px 0;
    }


    .Financing-single {
        margin-bottom: 30px;
    }

    .Financing-names {
        background-color: #fff;
        border-radius: 7px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
        border: solid 1px #e7e7e7;

        @include media-breakpoint-down(sm) {
            display: none;
        }


        a {
            border-bottom: 1px solid #e4e4e4;
            color: $brand-third;
            font-size: em(18px);
            font-weight: 200;
            display: block;
            padding: 15px 35px;
            position: relative;
            transition: color 500ms ease;

            @include media-breakpoint-up(lg) {
                font-size: em(20px);
            }


            &:hover {
                color: $brand-primary;
            }

            &.current {
                color: $brand-primary;

                &::after {
                    content: "\F054";
                    font-family: "Font Awesome 5 Free";
                    font-size: em(14px);
                    font-weight: 900;
                    -webkit-font-smoothing: antialiased;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    line-height: 1;
                    position: absolute;
                    right: 30px;
                    top: 22px;
                }
            }
        }
    }

    .Financing-handler {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .Financing-data {
        color: $brand-third;

        a {
            color: $brand-third;
            font-weight: 700;
            transition: color 500ms ease;

            @include hover {
                color: $brand-primary;
            }
        }
    }

    .Financing-text {
        figure {
            margin-bottom: 30px;
            width: 70%;

            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }
    }

    .Financing-name {
        color: $brand-primary;
        font-size: em(30px);
        font-weight: 200;
    }
}

.FinancingWrapp {
    @include media-breakpoint-down(sm) {
        background-color: #fff;
        border-radius: 7px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
        border: solid 1px #e7e7e7;

        .Financing-handler {
            border-bottom: 1px solid #e4e4e4;
            font-size: em(20px);
            font-weight: 200;
            display: flex;
            position: relative;
            justify-content: space-between;
            align-items: center;
            margin-left: -15px;
            margin-right: -15px;
            padding: 15px 35px;
            transition: color 500ms ease;

            &:last-of-type {
                border-bottom: none;
            }

            em {
                position: relative;
                height: 15px;
                width: 15px;
                transition: all 0.5s ease;

                &::before,
                &::after {
                    background-color: $brand-third;
                    content: "";
                    display: block;
                    position: absolute;

                    @include centrar;
                }

                &:before {
                    height: 2px;
                    width: 100%;
                    opacity: 1;
                    transition: opacity 500ms ease;
                }

                &::after {
                    height: 100%;
                    width: 2px;
                }
            }

            &.current {
                color: $brand-primary;

                em {
                    transform: rotate(90deg);

                    &::before,
                    &::after {
                        background-color: $brand-primary;
                    }

                    &::before {
                        opacity: 0;
                    }
                }
            }
        }
    }
}

.FinancingEstimate {
    .Estimateprice {
        padding: 0;

        @include media-breakpoint-up(md) {
            padding: 70px 45px 45px 45px;
        }
    }

    .EstimateDisclamer {
        @include media-breakpoint-up(md) {
            padding: 23px 15px;
        }
    }

    .Estimate {
        @include media-breakpoint-down(sm) {
            padding-top: 50px;
        }
    }
}

.FinancingBuild {
    overflow: hidden;

    .container {
        @include media-breakpoint-down(sm) {
            background: url("/images/shopping/back-build-xs.png") no-repeat 200% center;
            padding: 50px 15px;
        }


        @include media-breakpoint-up(md) {
            background: url(/images/shopping/back-build.png) no-repeat 130% center;
            padding: 65px 15px;
            position: relative;
            background-size: contain;

            &::after {
                background-color: $brand-primary;
                content: "";
                position: absolute;
                top: 0;
                left: 100%;
                height: 100%;
                width: 100%;
            }
        }

        @media #{$mq-2k} {
            background: url("/images/shopping/back-build.png") no-repeat 110% center;
            background-size: auto 100%;
        }
    }

    .buildRow {
        @include media-breakpoint-down(sm) {
            align-items: center;
        }
    }

    .FinancingBuild-text {
        h2 {
            text-align: left;
        }

        p {
            color: $brand-alternative;
            font-size: em(14px);
        }
    }

    .FinancingBuild-img {}
}
