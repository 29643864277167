.WorkShop {
    .ShortHeader {
        @include media-breakpoint-down(md) {
            background: url("/images/services/workshop-header-xs.jpg") no-repeat center top;
            background-size: cover;
        }

        @include media-breakpoint-up(lg) {
            background: url("/images/services/workshop-header.jpg") no-repeat center top;
            background-size: cover;
        }
    }
}

.WorkShop-certs {
    padding: 50px 0;

    .WorkShop-certs_text {
        h2 {
            color: $brand-primary;
            font-size: em(30px);
            font-weight: 200;
            margin: 0 0 20px 0;

            @include media-breakpoint-up(md) {
                margin: 30px 0;
            }
        }

        p {
            color: $brand-alternative;
            font-size: em(14px);
        }

        ul {
            padding: inherit;
            li {
                color: #848484;
                font-size: em(14px);
            }
        }
    }

    p {
        margin-bottom: 15px;
    }
}

.WorkShop-actions {
    margin-bottom: 50px;

    .shedule {
        display: block;
    }

    .BuilElem-row .BuildElem.wideElem .BuildElem-inner {
        @include media-breakpoint-down(sm) {
            align-items: center;

            figure {
                width: 11%;
            }

            .WorkShop-data {
                width: 73%;
            }

            h3+span {
                margin-bottom: 0;
            }
        }
    }
}

.WeOffers {
    background-color: #f6f6f6;
    padding: 50px 0;

    .WeOffers-block {
        h2 {
            color: $brand-primary;
            font-size: em(30px);
            font-weight: 200;
            text-align: center;
            margin-bottom: 50px;

            @include media-breakpoint-up(md) {
                font-size: em(50px);
                margin-bottom: 70px;
            }
        }
    }

    .SldrText-inner {
        text-align: center;
        padding-bottom: 30px;

        @include media-breakpoint-up(md) {
            text-align: left;
        }

        h3 {
            color: $brand-third;
            font-size: em(30px);
            font-weight: 200;
        }

        p {
            color: $brand-alternative;
            font-size: em(14px);

            @media #{$mq-2k} {
                //font-size: em(20px);
                margin-bottom: 15px;
            }
        }

        ul {
            position: relative;
            left: 20px;

            li {
                position: relative;
                color: #848484;
                font-size: em(15px);
            }
        }
    }

    .WeOffers-SldrImg {
        position: relative;

        &::after {
            background: url('/images/services/square-service-bg.jpg') no-repeat left top;
            background-size: cover;
            content: "";
            height: 145px;
            width: 140px;
            position: absolute;
            top: -25px;
            right: -25px;

            @include media-breakpoint-up(md) {
                height: 208px;
                width: 290px;
                top: -40px;
                right: -40px;
            }
        }

        .slick-list {
            z-index: 2;
        }
    }

    .WeOffers-Text {
        .slider-controls {
            @include media-breakpoint-up(lg) {
                left: 15px;
            }

            @media #{$mq-2k} {
                bottom: 0;
            }

        }
    }

    .WeOffers-SldrText {
        .slick-slide {
            line-height: normal;
        }
    }
}

.Parts {
    padding: 50px 0;

    .Parts-text {
        color: $brand-primary;
        font-size: em(30px);
        font-weight: 200;
        margin-bottom: 20px;
    }

    .Parts-data {
        color: $brand-alternative;
        font-size: em(14px);

        p {
            margin-bottom: 3px;
            label {
                font-weight: bold;
                font-size: em(15px);
            }
        }
    }
}
