.PreOwnedTop {
    .ShortHeader {
        @include media-breakpoint-down(md) {
            background: url("/images/preowned/preowned-header-xs.jpg") no-repeat center top;
            background-size: cover;
        }


        @include media-breakpoint-up(lg) {
            background: url("/images/preowned/preowned-header.jpg") no-repeat center top;
            background-size: cover;
        }
    }
}

.PreOwned-wish {

    .container {
        padding-top: 70px;
        padding-bottom: 250px;
        position: relative;

        @include media-breakpoint-up(md) {
            padding-bottom: 50px;
        }
    }

    .PreOwned-inst {
        color: $brand-alternative;
        text-align: center;
        margin-bottom: 20px;
    }

    .PreOwned-wishList {
        background: $aux-gray1;
        font-size: 13px;
        display: inline-flex;
        text-transform: uppercase;
        padding: 15px;
        position: absolute;
        top: 0;
        right: 0;

        a {
            color: $brand-third;
            display: flex;

            svg {
                margin-left: 5px;
            }
        }

        span {
            margin-right: 5px;
        }
    }

}

.PreOwned-cars {
    background-color: $aux-gray1;
    padding-bottom: 50px;

    @include media-breakpoint-up(md) {
        padding-bottom: 100px;
    }

    .PreOwned-filter {
        background-color: $brand-light;
        border-radius: 7px;
        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #e8e8e8;
        margin: 0 auto;
        margin-top: -250px;

        @include media-breakpoint-only(md) {
            height: auto;
            margin-top: -50px;
        }

        @include media-breakpoint-up(lg) {
            height: 185px;
            margin-top: -50px;
        }

        >.row {
            height: inherit;
        }
    }

    .PreOwned-filters {
        padding: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include media-breakpoint-up(md) {
            align-items: center;
            height: 100%;
            padding: 15px 45px;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 45px;
        }
    }

    .FilterType {
        min-width: 75px;
        margin-bottom: 15px;
        width: 45%;

        @include media-breakpoint-up(sm) {
            width: auto;
        }
        @include media-breakpoint-up(lg) {
            min-width: 70px;
            margin-top: 25px;
            margin-bottom: 0;
            margin-right: 2%;
            width: 15%;

            &.CarMil,
            &.CarPrice {
                margin-bottom: 25px;
                width: 23%;
            }

        }

        input[type=text] {
            display: inline-block;
            width: 75px;
            background: none;
            box-shadow: none;
            border-width: 0 0 1px 0;
            border-radius: 0;
            height: 40px;
            padding-left: 10px;
            font-size: em(14px);

            @include media-breakpoint-up(lg) {
                width: 75%;
                margin-left: 5%;
            }
        }


        span {
            color: $brand-primary;
            font-size: 14px;
            display: block;
            margin-bottom: 5px;
        }


        @include media-breakpoint-down(sm) {

            &.CarType {
                width: 100%;
            }

            &:last-child {
                margin-bottom: 0;
            }

            select {
                font-size: 14px;
            }
        }

        @include media-breakpoint-only(md) {

            &.CarType,
            &.CarBrand,
            &.CarModel,
            &.CarYear {
                width: 140px;
            }

            select {
                font-size: 14px;
            }
        }

    }

    .FilterSubmit {
        background: $brand-primary;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;

        @include media-breakpoint-up(md) {
            border-radius: 0;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
            //width: 137px;
            height: 100%;
        }
        @include media-breakpoint-up(lg) {
            background: transparent;
        }

        button {
            background-color: transparent;
            border: none;
            height: 55px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-up(md) {
                height: 100%;
            }
            @include media-breakpoint-up(lg) {
                background: $brand-primary;
                border-radius: 22px;
                height: 88px;
                margin-top: 50px;
                width: 137px;
                margin: 50px auto;
            }

            span {
                color: $brand-light;
                font-size: 14px;
                margin-right: 10px;

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }

            figure {
                height: 20px;
                width: 20px;
                margin: 0;
                line-height: 0;

                @include media-breakpoint-up(lg) {
                    height: 30px;
                    width: 30px;

                }
            }
        }
    }
}

.PreOwned-block_title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 50px 0 25px 0;

    h2 {
        margin-bottom: 0;
        line-height: 1;
    }

    a {
        font-size: 14px;
    }
}

.PreOwned-order {
    display: flex;
    align-items: center;

    span {
        color: $brand-primary;
        font-size: 14px;
        margin-right: 10px;
    }

    select {
        background: url("/images/arrow-down-b.svg") #fbfbfb no-repeat 95% center;
        color: $brand-third;
        width: 150px;
        height: 40px;
        border-radius: 4px;
        border: solid 1px #dedede;
        box-shadow: inset 0 1px 7px 0 rgba(0, 0, 0, 0.13);
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        font-size: em(14px);
        padding-left: 20px;

        @media #{$mq-2k} {
            font-size: em(16px);
        }
    }

    select:focus {
        outline: none;
    }
}

.block-title {
    color: $brand-primary;
    font-size: 30px;
    font-weight: 200;
}

.PreOwned-item {
    margin-bottom: 30px;

    .PreOwned-item_inner {
        border-radius: 7px;
        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
    }

    .PreOwned-item_data {
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }

    .PreOwned-item_name {
        h2 {
            color: $brand-third;
            font-size: 14px;
            margin-bottom: 0;

            @include media-breakpoint-up(md) {
                font-size: 16px;
            }
        }

        span {
            color: $brand-alternative;
            font-size: 14px;
        }
    }

    .PreOwned-item_price {
        color: $brand-primary;
        font-size: 20px;

        @include media-breakpoint-up(lg) {
            padding-left: 0;
        }
    }

}

.Favorite {
    position: relative;
    margin-bottom: 0;

    .addFavorite {
        cursor: pointer;
        height: 30px;
        width: 30px;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;

        svg {
            #svg_1 {
                fill: $brand-primary;
                opacity: 0.3;
                transition: fill 500ms ease;
            }
        }

        &.added,
        &:hover {
            svg {
                #svg_1 {
                    fill: $brand-primary;
                    opacity: 1;
                }
            }
        }
    }

    @include hover {
        .addFavorite {
            animation: pulse 1s ease infinite;
        }
    }
}

.PreOwned-featured {
    .PreOwned-item {
        @include media-breakpoint-down(sm) {
            &:last-child {
                //display: none;
            }
        }
    }
}

.PreOwned-results_w {

    .PreOwned-inst {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .container {
        @include media-breakpoint-down(sm) {
            padding-bottom: 20px;
        }
    }

    .PreOwned-results_f {
        @include media-breakpoint-up(md) {
            display: none;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            border-radius: 22px;
            background-color: $brand-primary;
            color: white;
            font-size: 14px;
            font-weight: bold;
            width: 100px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 5px;
            }
        }

        .PreOwned-order {
            select {
                width: 100px;
                padding-left: 5px;
            }
        }
    }
}

.PreOwned-results {
    .PreOwned-block_title .PreOwned-order {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .FilterBox {
        @include media-breakpoint-down(sm) {
            display: none;
        }

        .PreOwned-filter {
            @include media-breakpoint-down(sm) {
                margin-top: 0;
            }
        }
    }
}

.pagination {
    padding-top: 50px;

    a {
        margin: 0 auto;
    }
}

.secluded_car {
    position: absolute;
    width: 45%;
    background-color: $red1f;
    padding-left: 8%;
    font-size: em(13px);
    top: 5%;
    height: 35px;
    line-height: 36px;
    color: white;
    font-weight: bold;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
    text-transform: uppercase;

    &.detail {
        padding-left: 7%;
        width: 24%;
    }
}
