.OffersBox {
    select {
        text-align: center;
        text-align-last: center;
        padding-right: 30px;
    }

    option {
        background-color: $brand-primary;
    }

    .Offers-header {
        @include media-breakpoint-down(md) {
            background: url("/images/offers/header-offers-xs.jpg") no-repeat center top;
            background-size: cover;
            height: 200px;
        }


        @include media-breakpoint-up(lg) {
            background: url("/images/offers/header-offers.jpg") no-repeat center top;
            background-size: cover;
            height: 225px;
        }


        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
            color: white;
            font-size: em(40px);
            font-weight: 200;

            @include media-breakpoint-up(lg) {
                font-size: em(60px);
            }
        }
    }

    .modal .modal-content {
        top: 200px;
    }
}

.Offers-block {
    background-color: #f6f6f6;
    padding: 50px 15px;
}

.Offers-item {
    margin-bottom: 30px;
    text-align: center;

    .ShadowBox {
        height: 100%;
    }

    .Offers-inner {
        padding: 30px;
        height: calc(100% - 98px);
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(md) {
            padding-bottom: 0;
        }
    }

    .Offer-title {
        font-size: em(20px);
        font-weight: 200;
        margin-bottom: 20px;

        strong {
            font-weight: bold;
        }
    }

    .Offer-discount {
        color: $brand-primary;
        font-size: em(24px);
        font-weight: 200;

        @include media-breakpoint-up(lg) {
            font-size: em(30px);
            line-height: 28px;
            min-height: 50px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .Offer-specs {
        color: $brand-third;
        border-bottom: 1px solid #eeeeee;
        font-size: em(12px);
        padding-bottom: 10px;
        margin-bottom: 10px;
        flex: auto;
        //display: flex;
        align-items: center;
        @include media-breakpoint-up(md) {
            //min-height: 83px;
        }

        p {
            font-size: em(12px);
        }
    }

    small {
        display: block;
    }

    .Offer-disc {
        color: $brand-alternative;
        font-size: em(9px);
        padding-bottom: 10px;
        line-height: normal;
    }

    .Offer-expire {
        color: $brand-primary;
        font-size: em(9px);
        margin-bottom: 20px;
    }

    .Offer-action {
        background-color: #f8f8f8;
        border-radius: 7px;
        padding: 25px;
        text-align: center;
    }
}

//modal
.ModalStd {
    background: $brand-light;
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    height: calc(100vh - 70px);
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 10;
    overflow: auto;
    opacity: 0;
    visibility: hidden;

    &.openModal {
        opacity: 1;
        visibility: visible;
    }

    .ModalClose {
        cursor: pointer;
        position: absolute;
        z-index: 2;
        top: 15px;
        right: 15px;

        @include media-breakpoint-up(lg) {
            top: 18px;
            right: 30px;
        }
    }

    .ModalData {
        padding-top: 50px;
        text-align: center;

        @include media-breakpoint-up(md) {
            padding-top: 95px;
            text-align: left;
        }

        .ModalTitle {
            color: $brand-primary;
            font-size: em(50px);
            line-height: 42px;
            font-weight: 200;

            @include media-breakpoint-up(md) {
                font-size: em(38px);
            }

            @include media-breakpoint-up(lg) {
                font-size: em(52px);
            }

            @include media-breakpoint-up(xl) {
                font-size: em(60px);
            }
        }

        .ModalName {
            display: block;
            font-size: em(30px);
            font-weight: 200;
            margin-bottom: 30px;

            strong {
                font-weight: bold;
            }

            @include media-breakpoint-up(md) {
                font-size: em(30px);
                margin-bottom: 0;
            }
        }

        .ModalMeta {
            @include media-breakpoint-down(sm) {
                display: none;
            }

            > * {
                display: block;
            }

            .ModalOffer {
                color: $brand-primary;
                font-size: em(30px);
                font-weight: 200;

                @include media-breakpoint-up(md) {
                    font-size: em(30px);
                }
            }

            .ModalSpecs {
                border-bottom: 1px solid #eeeeee;
                font-size: em(14px);
                padding-bottom: 10px;
                margin-bottom: 10px;
            }

            small {
                font-size: em(9px);
                margin-bottom: 10px;

                &.ModelExpire {
                    color: $brand-primary;
                }
            }

        }

        figure {
            margin: 1rem 0 1rem 0;
        }
    }

    .ModalForm {
        background-color: #f6f6f6;
        padding-top: 50px;
        padding-bottom: 50px;

        @include media-breakpoint-up(md) {
            padding-top: 65px;
        }

        p {
            color: $brand-third;
            font-size: em(14px);
            text-align: center;

            @include media-breakpoint-up(md) {
                text-align: left;
            }
        }

        label {
            color: $brand-primary;
            font-size: em(12px);
        }

        .Modal-link {
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        input,
        textarea {
            color: $brand-third;
            font-size: em(16px);
        }
    }

    .ModalCoupon {
        background-image: url("/images/offers/back-coupon.jpg");
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 50px;
        padding-bottom: 50px;
        //text-align: center;

        @include media-breakpoint-up(md) {
            padding-top: 120px;
        }

        .ModalThumb {
            margin-bottom: 45px;

            @include media-breakpoint-up(md) {
                margin-bottom: 85px;
            }
        }

        a {
            width: 199px;
        }
    }
}
