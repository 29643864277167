.DealerBox {

    &.location {
        .ShortHeader {

            @include media-breakpoint-down(md) {
                background: url("/images/location-header-xs.jpg") no-repeat center top;
                background-size: cover;
            }


            @include media-breakpoint-up(lg) {
                background: url("/images/location-header.jpg") no-repeat center top;
                background-size: cover;
            }
        }
    }

    .ShortHeader {

        @include media-breakpoint-down(md) {
            background: url("/images/contact-header-xs.jpg") no-repeat center top;
            background-size: cover;
        }


        @include media-breakpoint-up(lg) {
            background: url("/images/contact-header.jpg") no-repeat center top;
            background-size: cover;
        }
    }
}

.Contact {
    padding-top: 50px;

    @include media-breakpoint-up(md) {
        padding: 50px 0;
    }

    .ContactBox-dealer {
        padding-bottom: 50px;

        h3 {
            color: $brand-primary;
            font-size: em(30px);
            font-weight: 200;

            @include media-breakpoint-up(md) {
                font-size: em(30px);
            }
        }
    }

    .ContactBox-form {
        background-color: #f6f6f6;
        border-top: solid 1px #dfdfdf;
        padding: 25px 35px;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            background-color: #fff;
            border-radius: 7px;
            box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
            border: solid 1px #e8e8e8;
            padding: 30px 60px;
        }

        .Contact-inst {
            display: block;
            margin-bottom: 20px;
        }

        input,
        select {
            color: $brand-third;
            font-size: em(16px);
        }

        textarea {
            min-height: 180px;
            max-height: 180px;
            height: 180px;
        }

        label {
            color: $brand-primary;
        }

        .BtnStd {
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }
}

.Adrs-icons {
    color: $brand-alternative;
    font-size: em(14px);

    @include media-breakpoint-only(md) {
        display: flex;
        flex-wrap: wrap;
    }

    dd {
        padding-left: 30px;
        margin-bottom: 10px;

        @include media-breakpoint-only(md) {
            width: 50%;
        }

        &.direction {
            background: url("/images/icon-location.svg") no-repeat left 5px;
            background-size: 14px;
        }

        &.phone {
            background: url("/images/icon-phone.svg") no-repeat left top;
            background-size: 18px;
        }

        &.shedule {
            background: url("/images/icon-date-red.svg") no-repeat left 5px;
        }

        &.email {
            background: url("/images/icon-mail.svg") no-repeat left 5px;
            background-size: 14px;
        }
    }

    a {
        color: $brand-alternative;

        &:hover,
        &:active {
            color: $brand-primary;
        }
    }
}

.Contact-tks {
    padding: 50px 0;


    @include media-breakpoint-up(md) {
        padding: 100px 0;
    }

    .Contact-tks_inner {
        margin: 0 auto;

        >* {
            display: block;
            text-align: center;
        }

        >span {
            color: $brand-third;
            font-size: em(30px);
            font-weight: 200;
            padding-top: 20px;

            @include media-breakpoint-up(md) {
                font-size: em(50px);
            }
        }

        small {
            color: $brand-alternative;
            font-size: em(19.2px);
            margin-bottom: 30px;
        }
    }
}
