@import "mixins";

#recall {
    position: relative;
    padding-top: 30px;
    overflow: hidden;
    @include screen-sm {
        //background-image: url('../../img/recall/bg_recall_sm.jpg');
        background-color: #d9d9d9;
        background-size: 100% auto;
        background-position: top center;
        padding-top: 180px;
        padding-bottom: 100px;
    }
    @include screen-md {
        //background-image: url('../../img/recall/bg_recall.jpg');
        background-size: auto auto;
    }

    @include screen-lg {
        padding-bottom: 143px;
    }

}

.recall-title {
    font-size: 29px;
    font-weight: 300;
    margin: 20px 0 30px;

    @include screen-sm {
        font-size: 34px;
    }

    @include screen-md {
        font-size: 46px;
    }
}

.recall-block1 {
    padding-bottom: 40px;
}

.recall-search-vin, .recall-block1 {
    @include screen-sm {
        padding-left: 15px;
    }
    padding-left: 8.3333333333%;
}

.recall-search-vin {
    background-color: #efefef;
    padding-top: 40px;
    padding-bottom: 60px;
    box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.1);

    @include screen-sm {
        background-color: transparent;
        padding-top: 50px;
        padding-bottom: 0;
        box-shadow: none;
    }

    h2 {
        font-size: 23px;
        font-weight: 300;
        margin-bottom: 25px;
        @include screen-sm {
            margin-bottom: 10px;
        }
    }

    input[type=text] {
        background: white;
        border: 1px solid $brand-primary;
        height: 50px;
        width: 100%;
        margin: 20px 0;
        outline: none;
        padding: 0 5px;
        @include screen-sm {
            margin: 10px 0;
        }
    }

    button {
        background-color: $yellow00;
        border: none;
        color: white;
        text-transform: uppercase;
        height: 40px;
        width: 100%;
        margin: 20px 0;
        position: relative;
        outline: none;

        @include screen-sm {
            margin: 10px 0;
        }

        .result-loader {
            background: $red1f;
            display: block;
            height: inherit;
            width: 0%;
            transition: width 3s ease;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            &.loading {
                width: 100%;
            }
        }

        .result-check-text {
            display: block;
            height: inherit;
            line-height: 40px;
            width: inherit;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }

        .result-loader-gif {
            bottom: 20%;
            display: none;
            position: absolute;
            right: 19%;
            width: 23px;
            &.loading {
                display: block;
            }
        }

    }
    small {

        &#error-catch {
            color: $brand-primary;
            display: block;
        }
        //
        color: $gray32;
        font-size: 13px;
    }

    .wrap-description-image {
        border: 1px solid;
        height: auto;
        margin-top: 40px;
        padding-bottom: 20px;
        position: relative;
        width: 100%;

        .content-text {
            background-color: #1c1c1c;
            padding-bottom: 20px;
            padding-top: 20px;
            position: relative;

            .message {
                color: white;
                display: block;
                font-style: italic;
                font-size: em(14px);
                text-transform: uppercase;
                margin-left: 15px;
                margin-right: 15px;
                position: relative;

                @include screen-sm {
                    font-size: em(24px);
                }
                @include screen-md {
                    font-size: em(28px);
                }

                &.es {
                    font-size: em(14px);
                    @include screen-md {
                        font-size: em(25px);
                    }
                }
            }
            //.message
            .models {
                color: $brand-primary;
                display: block;
                font-style: italic;
                font-size: em(14px);
                text-transform: uppercase;
                margin-left: 15px;
                position: relative;
                width: 100%;

                @include screen-sm {
                    width: auto;
                }
                //@include screen-sm
            }
            //.models
        }
        //.content-text

        .img-safety {
            bottom: 0;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;
            position: relative;
            width: 130px;

            @include screen-sm {
                margin: 0 auto;
                width: 200px;
            }
            @include screen-md {
                width: 250px;
            }//@include screen-md

        }//.img-safety

        .attention {
            display: block;
            margin: 0 auto 30px;
            width: 90%;

            &.title {
                font-style: italic;
                margin: 0 auto;
                margin-top: 20px;
            }
        }
        //.attention

        .disclaimer {
            display: block;
            font-size: em(12px);
            font-weight: bold;
            margin: 20px auto 0;
            text-align: justify;
            width: 90%;

            &.first {
                font-size: em(12px);
                font-style: italic;
                margin: 0 auto;
                text-align: center;
            }
        }
        //.disclaimer
    }
    //wrap-description-image
}

.recall-options {
    overflow: hidden;
    a {
        color: $gray0c;
        font-size: 14px;
        display: block;
        width: 30%;
        text-decoration: underline;

        @include screen-lg {
            font-size: em(10px);
        }

        &:first-child {
            float: left;
        }

        &:last-child {
            float: right;
            text-align: right;
        }

        @include screen-sm {
            width: auto;
        }

    }
}

/*
 * Results
 */
.results-box {
    background-color: white;
    padding: 45px 0;
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    transform: translateX(100%);
    transition: transform 500ms ease;
    z-index: 15;
    overflow-y: auto;
    &.showResults {
        transform: translateX(0);
    }

    @include screen-sm {
        right: 0;
        width: 65%;
    }
    @include screen-lg {
        width: 50%;
    }
}

.results-list {
    margin: 0;
    li {
        display: block;
        font-weight: 300;
        padding: 25px 0;

        &:nth-child(even) {
            background: $grayf7;
            border-top: 1px solid $graydc;
            border-bottom: 1px solid $graydc;
        }

        h3 {
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        span {
            display: block;
            em {
                font-style: normal;
                font-weight: 600;

                &.red {
                    color: $brand-primary;
                }
            }

            &.results-vin, &.results-title {
                margin-bottom: 15px;
            }

            &.results-vin {
                font-size: 18px;
                @include screen-sm {
                    font-size: 24px;
                }
            }

            &.results-title {
                color: $red1f;
                font-size: 15px;
                text-transform: uppercase;
                font-weight: 600;

            }
        }
    }
}

.results-close {
    cursor: pointer;
    position: fixed;
    width: 40px;
    height: 40px;
    top: 8px;
    right: 20px;
    display: block;

    &::after, &::before {
        background: $gray0c;
        content: "";
        display: block;
        height: 2px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
    }
    &::after {
        transform: rotate(45deg);
    }
    &::before {
        transform: rotate(-45deg);
    }

    @include screen-sm {
        top: 35px;
    }
}

/**
 * Convert px to em given a base size.
 */
@function em($px, $base: $baseFontSize) {
    @return ($px / $base) * 1em;
}

@import "recallv2";
@import "modal-vin";


