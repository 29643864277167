.PreOwned-detail {
    padding-top: 40px;

    .PreOwned-detail-elem {}

    .PreOwned-detail-Car {
        background: $aux-gray1;
        align-items: flex-start;
        padding: 15px 0;

        @include media-breakpoint-up(md) {
            background-color: white;
            padding-top: 0;
        }
    }

    .PreOwned-back {
        margin-bottom: 30px;

        a {
            color: $brand-third;
            font-size: 14px;
            display: inline-block;
            transition: color 500ms ease;

            i {
                margin-right: 5px;
                transition: transform 500ms ease;
            }

            @include hover {
                color: $brand-primary;

                i {
                    transform: translateX(-5px);
                }
            }
        }
    }

    .PreOwned-CarName {
        .PreOwned-CarTitle {
            color: $brand-primary;
            font-size: 30px;
            font-weight: 200;
            line-height: 1;
            margin-bottom: 0;

            @include media-breakpoint-up(md) {
                font-size: 50px;
            }
        }

        span {
            color: $brand-alternative;
            display: block;
            margin-left: 3px;

            @include media-breakpoint-up(md) {
                font-size: 18px;
            }
        }
    }

    .PreOwned-CarPrice {
        @include media-breakpoint-down(sm) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }


        >span {
            color: $brand-primary;
            font-size: 25px;

            @include media-breakpoint-up(md) {
                font-size: 40px;
            }
        }

        a {
            min-width: 115px;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }
}

.PreOwned-detail_data {
    background: $aux-gray1;

    @include media-breakpoint-up(md) {
        padding: 40px 0;
    }


    .CarDetail-sldrBox {
        @include media-breakpoint-up(md) {
            background-color: #fff;
            border-radius: 7px;
            box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
            padding-top: 39px;
        }
    }

    .CarDetail-sldr_item {
        figure {
            margin: 0;
        }
    }

    .CarDetail-thumb-row {
        @include media-breakpoint-down(sm) {
            margin-left: 0px;
        }
    }

    .CarDetail-thumbBox {
        padding: 27px 0;

        @include media-breakpoint-up(md) {
            margin: 0 auto;
        }


        .CarDetail-thumb_i {
            padding: 0 5px;
        }

        .CarDetail-thumb {
            .slick-list {
                @include media-breakpoint-down(sm) {
                    padding: 0 10% 0 0;
                }
            }
        }
    }
}

.SmallSub {
    color: $brand-primary;
    font-size: 30px;
    font-weight: 200;
    padding-bottom: 10px;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
        font-size: 30px;
    }


    &.underLine {
        border-bottom: 1px solid #dedede;
    }
}

.CarDetail-specs {
    padding-top: 50px;
    padding-bottom: 20px;

    @include media-breakpoint-up(lg) {
        padding-bottom: 50px;
    }
}

.CarDetail-specs_wrapp {
    display: flex;
    flex-wrap: wrap;
}

.CarDetail-specs_item {
    display: flex;
    margin-bottom: 30px;
    min-width: 50%;

    @include media-breakpoint-up(md) {
        min-width: 33.3333333333%;
    }


    figure {
        min-width: 40px;
        text-align: center;
    }

    .CarDetail-spec {
        color: $brand-alternative;
        font-size: 14px;
        margin-left: 10px;

        span {
            font-size: 16px;
            display: block;
        }
    }
}

.CarDetail-desc_txt {
    color: $brand-third;
    font-size: 14px;
}

.Interesting {
    border-top: 1px solid #dedede;
    padding: 50px 15px;

    @include media-breakpoint-up(lg) {
        border: none;
        padding: 0;
    }


    >span {
        color: $brand-third;
        font-size: 14px;
        display: block;
        text-align: center;
        margin-bottom: 5px;
    }

    .CarDetail-form {
        overflow: hidden;

        .EstimateForm {
            color: $brand-third;
            padding: 15px 20px;

            >span {
                font-size: 14px;
                display: block;
                margin-bottom: 15px;
            }

            label {
                color: $brand-primary;
                font-size: 14px;
                margin-bottom: 0;
            }

            input {
                color: $brand-third;
                font-size: 16px;
            }
        }
    }

    .CarDetail-phone {
        background-color: $brand-primary;
        color: white;
        font-size: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;

        @include media-breakpoint-up(md) {
            font-size: 25px;
        }


        figure {
            margin-bottom: 0;
        }
    }
}

.CarDetail-related {
    padding: 50px 0;

    .CarDetail-related_title {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 30px;

        h2 {
            margin-bottom: 0;
            line-height: 1;
        }
    }
}

.CarDetail-related-Sldr {
    .slick-list {
        @include media-breakpoint-down(sm) {
            padding: 0 10% 0 0;
        }
    }

    .slick-slide {
        line-height: normal;
    }
}
