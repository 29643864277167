.PartRequestForm {

    .disclaimer {
        color: $brand-alternative;
        font-size: em(10px);
        display: block;
        text-align: center;
        padding-top: 15px;

        @include media-breakpoint-up(xl) {
            padding-top: 0;
            padding-left: 15px;
            text-align: left;
        }
    }

    .add-part {
        span {
            background-image: url("/images/icon-close-red.svg");
            background-repeat: no-repeat;
            background-size: 100% auto;
            display: inline-block;
            height: 20px;
            margin-right: 10px;
            transform: rotate(45deg);
            width: 20px;
        }
    }

    .delete-field {
        height: 20px;
        width: 20px;
        position: absolute;
        top: 33px;
        right: 10px;
        cursor: pointer;

        img {
            height: auto;
            width: 100%;
        }
    }

}
