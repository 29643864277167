@import "~bootstrap/scss/bootstrap";
address, dl, ol, ul {
    margin-bottom: 0;
}
@import "functions";
@import "front/settings";
@import "front/mixins";

@import "front/layout/header";

//shopping
@import "front/shopping-tools/financing";
@import "front/shopping-tools/offers";
@import "front/shopping-tools/testdrive";

//Recall
@import "front/recall/recall";
//Services
@import "front/services/workshop";
@import "front/services/recall";
@import "front/services/center";
@import "front/services/online-appointment";
@import "front/services/know-your-honda";
@import "front/services/express";
@import "front/services/center-new";
@import "front/services/parts-request";

//Contact
@import "front/contact/contact";

//about
@import "front/about/about";

//preowned
@import "front/preowned/preowned";
@import "front/preowned/detail";
