.know-your-honda {
    background-color: #f6f6f6;

    .all-content-wrap {
        padding-bottom: 45px;
        padding-top: 45px;

        .parts-carrousel {

            &.partDsk{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                .item{

                    a{
                        color:#343434;
                        font-size: 20px;
                        text-align: center;
                        font-weight: 200;
                        display: block;
                        transition: all 250ms ease;
                        position: relative;

                        &.current{
                            color:$brand-primary;
                            text-decoration: underline;

                            @include media-breakpoint-up(md){
                                &:after {
                                    background-color: $brand-primary;
                                    bottom: 0;
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    height: 3px;
                                    width: 100%;
                                }
                            }
                        }
                    }
                    figure{
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        justify-content: center;
                        height: 60px;
                        text-align: center;

                        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                            display: block;
                        }
                    }
                }
            }

            &.slick-slider{
                .item {
                    //transition: all 500ms ease;
                    display: inline-flex;
                    height: 120px;

                    &.right-alignment {
                        .part {
                            text-align: right;
                            float: right;
                        }
                    }

                    &.center-alignment {
                        .part {
                            text-align: center;
                            margin: 0 auto;
                            //transition: all 500ms ease;
                        }
                    }

                    .part {
                        //height: 75px;
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: row;
                        justify-content: center;
                        align-content: flex-start;
                        height: inherit;
                        position: relative;
                        margin: 0;
                        text-align: center;
                        padding: 0 15px;
                        // text-align: left;
                        // width: 170px;
                        transition: all 500ms ease;

                        &.exterior {
                            //height: 50px;
                            //padding-top: 20px;
                            //width: 112px;
                        }
                        &.under-the-car {
                            //padding-top: 20px;
                            //height: 115px;
                        }
                        &.under-the-hood {
                            //padding-top: 20px;
                        }
                        figure{
                            height: 63px;
                            display: flex;
                            align-items: center;
                        }
                        img {
                            display: inline-block !important;
                        }

                        label {
                            color: #343434;
                            font-size: em(20px);
                            display: block;
                            //height: 22px;
                            line-height: 22px;
                            //margin-top: 15px;
                        }
                    }
                }
                .slick-current{
                    .part label{
                        color:$brand-primary;
                    }
                }
            }
        }

        .slick-navDots {
            position: relative;
            @include media-breakpoint-up(md){
                display: none;
            }
            .navDots {
                list-style-type: none;
                position: relative;
                height: 20px;
                width: 200px;

                .slick-dots {
                    bottom: 0;

                    @include media-breakpoint-up(sm) {
                        width: 276px;
                        margin: 0 auto;
                        left: 0;
                        right: 0;
                        text-align: center;
                    }

                    li {
                        &.slick-active {
                            button {
                                &:before {
                                    color: $brand-primary;
                                }
                            }
                        }

                        button {
                            &:before {
                                color: #e2e2e2;
                            }
                        }
                    }
                }
            }
            //.navDots

            .navArrow {
                position: absolute;
                top: 0;
                margin: 0 auto;
                left: 0;
                right: 0;
                width: 276px;

                >ul>li{
                    float: left;
                    >ul{
                        text-align: center;
                        list-style-type: none;
                    }
                }

                .slick-arrow {
                    list-style: none;
                    float: left;
                    height: 20px;
                    width: 20px;

                    &.prev {
                        &:before {
                            background-image: url("../images/ico-arrow.svg");
                            background-repeat: no-repeat;
                            background-position: 0 0;
                            background-size: 63% auto;
                            content: "";
                            height: 20px;
                            width: 20px;
                            display: block;
                        }
                    }

                    &.next {
                        float: right;
                        &:before {
                            background-image: url("../images/ico-arrow.svg");
                            background-repeat: no-repeat;
                            background-position: 0 0;
                            background-size: 63% auto;
                            content: "";
                            height: 20px;
                            width: 20px;
                            display: block;
                            transform: rotate(180deg);
                        }
                    }
                }

            }
            //.navArrow

        }
        //.slick-dots
        .know-data{
            padding-top: 60px;
        }
        .videophoto{
            padding-bottom: 35px;
            padding-top: 35px;
            padding-right: 15px;
            padding-left: 15px;
            text-align: center;
            display: flex;
            flex-direction: column;
            &-link{
                color:$brand-primary;
                cursor: pointer;
                font-size: 17px;
                font-weight: 200;
                display: inline-flex;
                align-items: center;
                margin-bottom: 10px;
                span{
                    width: 24px;
                    margin-right: 10px;
                }
            }
        }
        .wrap-categories {
            position: relative;
            margin-top: 58px;
            transition: all $transitionTime ease;

            ul {
                list-style: none;
                border-bottom-left-radius: 7px;
                border-bottom-right-radius: 7px;
                transition: all $transitionTime ease;

                @include media-breakpoint-up(sm) {
                    //width: 295px;
                }

                li {
                    background-color: white;
                    display: block;
                    padding-left: 38px;
                    padding-bottom: 5px;
                    padding-top: 5px;
                    height: 48px;
                    line-height: 45px;
                    width: 100%;
                    transition: all $transitionTime ease;

                    @include media-breakpoint-up(sm) {
                        padding-left: 20px;
                    }

                    &.logo {
                        background-color: #050605 !important;
                        border-top-left-radius: 7px;
                        border-top-right-radius: 7px;
                        padding-left: 0;
                        text-align: center;
                        height: auto;

                        img {
                            display: inline-block;
                            padding: 20px;
                            width: 65%;

                            @include media-breakpoint-up(sm) {
                                padding: 8px;
                                width: 209px;
                            }
                        }
                    }

                    &.header {
                        background-color: $brand-primary;
                        color: white;
                        border-radius: 7px;
                        position: relative;
                        height: auto;

                        &:before {
                            background-color: #050605;
                            content: '';
                            display: block;
                            position: absolute;
                            height: 10px;
                            top: 0;
                            left: 0;
                            width: 100%;
                            z-index: -1;
                        }
                        a {
                            color: white;
                        }

                        &.active, &:hover, &:active {
                            background-color: $brand-primary;
                        }
                    }

                    &.active {
                        &.subcategory {
                            &:after {
                                transform: rotate(90deg);
                            }
                        }
                    }

                    &.subcategory {
                        position: relative;
                        transition: all $transitionTime ease;
                        &:after {
                            background-image: url(../images/ico-arrow-white.svg);
                            background-repeat: no-repeat;
                            background-position: 0 0;
                            background-size: 63% auto;
                            content: "";
                            height: 20px;
                            width: 20px;
                            display: block;
                            position: absolute;
                            right: 15px;
                            top: 20px;
                            transform: rotate(-90deg);
                        }
                    }
                    &.subcategory-red {
                        position: relative;
                        transition: all $transitionTime ease;
                        &:after {
                            background-image: url(../images/ico-arrow-red.svg);
                            background-repeat: no-repeat;
                            background-position: 0 0;
                            background-size: 65% auto;
                            content: "";
                            height: 15px;
                            width: 15px;
                            position: relative;
                            transform: rotate(-180deg);
                            display: inline-block;
                            margin-left: 15px;

                            @include media-breakpoint-up(sm) {
                                margin-left: 5px;
                            }
                        }
                    }

                    &.border-l {
                        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
                        border-left-width: 1px;
                        border-left-color: #e0e0e0;
                        border-left-style: solid;
                    }
                    &.border-r {
                        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
                        border-right-width: 1px;
                        border-right-color: #e0e0e0;
                        border-right-style: solid;
                    }
                    &.border-b {
                        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
                        border-bottom-width: 1px;
                        border-bottom-color: #e0e0e0;
                        border-bottom-style: solid;
                    }

                    &.tint, &:hover, &:active {
                        background-color: #f1f1f1;
                    }

                    a {
                        color: #454444;
                        display: block;
                        height: 100%;
                        line-height: 48px;
                        width: 100%;

                        @include media-breakpoint-up(sm) {
                            font-weight: normal;
                            font-size: em(18px);
                        }
                    }

                    &.collapse {
                        border-bottom-left-radius: 7px;
                        border-bottom-right-radius: 7px;
                        height: 0;
                        overflow: hidden;
                        padding-top: 0;
                        padding-bottom: 0;
                        padding-left: 0;
                        width: 100%;
                        transition: all $transitionTime ease;

                        &.active {
                            height: auto;
                        }

                        ul {
                            &.intern {
                                li {
                                    @include media-breakpoint-up(sm) {
                                        font-size: em(14px);
                                        min-height: 47px;
                                        height: auto;
                                        border-bottom: 1px solid lightgray;
                                    }
                                    @include media-breakpoint-up(md) {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                    //.collapse
                }
            }
        }
        //.wrap-categories

        .wrap-info {
            margin-top: 45px;

            .title {
                color: $brand-primary;
                font-size: em(30px);
                font-weight: 200;
                margin-bottom: 20px;
                margin-top: 0;
                text-align: left;
            }

            .description {
                font-size: em(14px);

                p{
                    margin-bottom: 20px;
                }
                ul {
                    margin-left: 15px;
                    margin-bottom: 20px;
                }

                table {
                    border: 1px solid lightgray;
                    border-radius: 5px;

                    tr {

                        th {
                            border: 1px solid lightgray;
                            text-align: center;

                            &.second {
                                width: 42.6%;
                            }
                        }
                        td {

                            &.no-padding {
                                padding: 0;
                            }

                            &:first-child {
                                text-align: center;
                                width: 15%;
                            }

                            border: 1px solid lightgray;
                            padding: 15px;

                            table {
                                border: 0;
                                width: 100%;

                                td {
                                    border: 0;
                                    width: 40% !important;

                                    &.w-border-l {
                                        border-left: 1px solid lightgray;
                                    }
                                    &.w-border-r {
                                        border-right: 1px solid lightgray;
                                    }
                                    &.w-border-b {
                                        border-bottom: 1px solid lightgray;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        //.wrap-info

        .wrap-videophoto {
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }

    }
    //.all-content-wrap

}
@import 'knowmodal';
